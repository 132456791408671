import envs from "config/envs";

const generateSongUri = (id: string) => {
  return `${envs.apiBaseUrl}/song/${id}?timestamp=${new Date().getTime()}`;
};

export const generateChargeUri = (id: string) => {
  return `${
    envs.apiBaseUrl
  }/song/create-play/${id}?timestamp=${new Date().getTime()}`;
};

export default generateSongUri;
