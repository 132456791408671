import React, { FC } from "react";
import starFarmLogo from "../../picture/starFarm.png";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface IThemeProps {
  size: number;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) => ({
  logo: {
    backgroundImage: `url(${starFarmLogo})`,
    width: (props) => props.size,
    height: (props) => props.size,
    backgroundSize: "cover",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
  },
}));

interface IStarfarmLogoProps {
  size: number;
}

const StarfarmLogo: FC<IStarfarmLogoProps> = ({ size }) => {
  const classes = useStyles({ size });

  return <div className={classes.logo} />;
};

export default StarfarmLogo;
