import gql from "graphql-tag";

const setAutomaticReload = gql`
  mutation setAutomaticReload($reload: Boolean!) {
    setAutomaticReload(reload: $reload) {
      _id
    }
  }
`;

export interface setAutomaticReloadResponse {
  user: NewUser;
}

export interface setAutomaticReloadVariables {
  reload: boolean;
}

export default setAutomaticReload;
