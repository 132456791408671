import React, { FC, useEffect, useContext } from "react";
import TokenManager from "../core/auth/TokenManager";

interface IUnauthenticatedProps {}

const Unauthenticated: FC<IUnauthenticatedProps> = (props) => {
  const manager = useContext(TokenManager);
  const token: any = manager.getToken();
  const redirectPath = window.location.search;
  useEffect(() => {
    window.location.href = redirectPath ? `/login${redirectPath}` : "/login";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <></>;
};

export default Unauthenticated;
