import React from "react";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    backgroundColor: "rgb(255 255 255 / 21%)",
    padding: "50px",
    borderRadius: "10px",
    width: "700px",
  },
  link: {
    textDecoration: "none",
    color: "#02172a",
  },
  title: {
    color: "white",
    fontFamily: "simplifica",
    fontSize: "64px",
  },
  verifyText:{
    color: "white",
    fontFamily: "simplifica",
    fontSize: "44px",
    marginTop:"0"
  },
  subtitle: { color: "rgba(84, 186, 180, 0.5)", marginBottom: "40px" },
});

const UserVerificationPage = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.contentContainer}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box>
              <Typography variant="h2" className={classes.title}>
                StarFarm.
              </Typography>

              <Typography variant="h4" className={classes.subtitle}>
                We Grow Stars.
              </Typography>
            </Box>
            <Typography variant="h1" className={classes.verifyText}>
              Please check your mail for email verification
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserVerificationPage;
