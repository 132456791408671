import React, { FC, useContext } from "react";
import MoreButton from "pages/Desktop/components/playlistcomponents/MoreButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { MenuItem, withStyles } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import ReportFormContext from "stores/ReportFormStore";
import { observer } from "mobx-react-lite";
import TokenManager from "core/auth/TokenManager";

interface MoreMenuButtonProps {
  type: string;
  id?: string;
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "rgba(255, 255, 255, 0.75)",
    background: "rgba(16, 53, 77, 0.54",

    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "rgba(255, 255, 255, 0.75)",
      },
    },
    "&::hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
}))(MenuItem);

const MoreMenuButton: FC<MoreMenuButtonProps> = observer(({ type, id }) => {
  const store = useContext(ReportFormContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOpenReportModal = () => {
    if (token === 'undefined') {
      window.location.href = "/login"
    }
    else {
      store.openModal();
      store.setType(type);
      if (id) {
        store.setId(id);
      }
    }
  };

  return (
    <MoreButton
      anchor={anchorEl}
      setOpen={handleClick}
      setClose={setAnchorEl}
      dropDownIcon={<MoreHorizIcon />}
    >
      <StyledMenuItem onClick={handleOpenReportModal}>
        <ListItemText primary="Report" />
      </StyledMenuItem>
    </MoreButton>
  );
});

export default MoreMenuButton;
