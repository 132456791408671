import gql from "graphql-tag";

const GET_ARTIST_BY_ID = gql`
	query ArtistByID($id: MongoID!) {
		artist: findArtist(filter: { _id: $id }) {
			_id
			description
			displayName
			bannerImage
			shortLink
			image
			songs {
				costPerPlay
				disabled
				album {
					title
					_id
				}

				name
				plays
				weeklyPlays
				image
				fileLink
				_id
				genre

				primaryArtist {
					displayName
					_id
					image
				}
			}

			albums {
				title
				disabled
				createdAt
				imageUrl
				featuredByArtist
				_id
				isSingle
				primaryArtist {
					displayName
					_id
				}
				releaseDate
			}
		}
	}
`;

export interface GET_ARTIST_BY_IDResponse {
	artist: NewArtist;
}

export interface GET_ARTIST_BY_IDVariables {
	id: string;
}

export default GET_ARTIST_BY_ID;
