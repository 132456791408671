import gql from "graphql-tag";

const findUserNotification = gql`
query($id:String,$offset:Int,$limit:Int){
    findUserNotifications(userId:$id, offset:$offset, limit:$limit){
    total,
    notifications{
    _id,
    title,
    body,
    contentId,
    type,
    albumId,
    }
    }
    }
`
export interface findUserNotificationResponse{
    findUserNotifications:Notification
}


export interface findUserNotificationVariables {
    id: string|undefined,
    offset:number,
    limit:number
  }

export default findUserNotification

