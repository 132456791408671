import CREATE_USER, {
  CREATE_USERVariables,
  CREATE_USERResonse,
} from "core/graphql/new/mutations/CreateUser";
import useUnauthenticatedClient from "jobs/UnathenicatedClient";
import RegistrationOne from "pages/MobileRegistration/RegistrationPageOne";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RegistrationPageTwo from "./RegistrationPageTwo";
import { TokenManager } from "../../core/auth/TokenManager";
import { observer } from "mobx-react-lite";
import getEnvironmentVariable from "../../core/utils/getEnvironmentVariable";
import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import ALL_COUNTRIES from "core/graphql/new/mutations/getAllCountries";

const accountURL = getEnvironmentVariable("REACT_APP_BENE_URI");

const Registration = () => {
  const history = useHistory();

  const client = useUnauthenticatedClient();

  const [isArtist, setIsArtist] = useState(false);

  const [tokenManager] = useState(new TokenManager());

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [choice, setChoice] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  // const [graphqlClient, setGraphqlClient] = useState<
  //   ApolloClient<any> | undefined
  // >();
  const [errorMessage, setErrorMessage] = useState("");
  const [alphaCode, setAlphaCode] = useState<any>();
  const [allContriesByQuery, setAllCountriesByQuery] = useState([]);
  const [artistDisplayName, setArtistDisplayName] = useState<
    string | undefined
  >();

  // useEffect(()=>{
  //   setGraphqlClient(client)
  // },[])

  const [submit, setSubmit] = useState(false);

  const handleArtistSelect = () => {
    setChoice("artist");
    setIsArtist(true);
  };

  const handleStreamerSelect = () => {
    setChoice("streamer");
    setIsArtist(false);
  };

  const handleNext = () => {
    setPage(page + 1);
    setErrorMessage("");
  };

  const handleBack = () => {
    setPage(page - 1);
    setErrorMessage("");
  };

  const handleSubmitPassword = (password: string) => {
    setPassword(password);
  };
  

  const handleSubmitEmail = (email: string) => {
    setEmail(email);
  };

  const handleSubmitFirstName = (firstName: string) => {
    setFirstName(firstName);
  };

  const handleSubmitLastName = (lastName: string) => {
    setLastName(lastName);
  };

  const handleCreateUser = async () => {
    setLoading(true);
    try {
      const { data } = await client.mutate<
        CREATE_USERResonse,
        CREATE_USERVariables
      >({
        mutation: CREATE_USER,
        variables: {
          email: email,
          country: alphaCode,
          password: password,
          isArtist: isArtist,
          artistDisplayName: artistDisplayName,
          firstName: firstName,
          lastName: lastName,
        },
      });

      setLoading(false);

      if (!data?.token) {
        throw new Error("Unsuccessul login attempt");
      }

      // tokenManager.storeToken(data?.token);

      if (
        tokenManager.decodedToken?.isArtist ||
        tokenManager.decodedToken?.isBeneficiary
      ) {
        window.location.href = accountURL;

        return;
      }

      history.push("/verify-user");
    } catch (err) {
      const typedError: any = err as Error;
      console.log(typedError?.graphQLErrors[0]?.message);
      setErrorMessage(typedError?.graphQLErrors[0]?.message);
    }
  };

  const handleSubmit = () => {
    setSubmit(true);
  };

  useEffect(() => {
    if (!submit) {
      return;
    }
    handleCreateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <>
      <ApolloProvider client={client}>
        {page === 1 && (
          <RegistrationOne
            choice={choice}
            handleArtistSelect={handleArtistSelect}
            handleStreamerSelect={handleStreamerSelect}
            handleNext={handleNext}
          />
        )}

        {page === 2 && (
          <RegistrationPageTwo
            handleSubmitArtistName={setArtistDisplayName}
            isArtist={isArtist}
            choice={choice}
            handleBack={handleBack}
            handleSignUp={handleSubmit}
            handleSubmitEmail={handleSubmitEmail}
            handleSubmitPassword={handleSubmitPassword}
            handleSubmitFirstName={handleSubmitFirstName}
            handleSubmitLastName={handleSubmitLastName}
            errorMessage={errorMessage}
            loading={loading}
            setErrorMessage={setErrorMessage}
            setLoading={setLoading}
            setSubmit={setSubmit}
            setAllCountriesByQuery={setAllCountriesByQuery}
            allContriesByQuery={allContriesByQuery}
            setAlphaCode={setAlphaCode}
            alphaCode={alphaCode}
          />
        )}
      </ApolloProvider>
    </>
  );
};

export default observer(Registration);
