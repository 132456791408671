import gql from "graphql-tag";

const GET_TOP_GENRES = gql`
  query {
    genres: findGenreMany(sort: PLAYS_DESC, limit: 10) {
      _id
      name
    }
  }
`;

export interface GET_TOP_GENRESResponse {
  genres: Genre[];
}

export default GET_TOP_GENRES;
