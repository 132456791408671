import Box from "@material-ui/core/Box/Box";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import { colors } from "fonts/colors";
import { fonts } from "fonts/fonts";
import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";

const useStyles = (backgroundColor: string, fontFam: string) =>
  makeStyles((theme) =>
    createStyles({
      container: {
        backgroundColor: backgroundColor,
        width: "40vw",
        height: "40vw",
        maxHeight: 125,
        maxWidth: 200,
        marginRight: theme.spacing(2),
        borderRadius: 6,
      },
      textStyle: {
        fontFamily: fontFam,
        whiteSpace: "normal",
      },
      links: {
        color: "inherit",
        textDecoration: "none",
        textTransform: "none",
        display: "inline-block",
        verticalAlign: "top",
      },
    })
  );

interface IGenreItemProps {
  genre: string;
  colorIndex: number;
}

const GenreItem: FC<IGenreItemProps> = ({ genre, colorIndex }) => {
  const colorForBackground = colors[colorIndex];

  const fontFam = useMemo(() => {
    return fonts[colorIndex];
  }, [colorIndex]);

  const classes = useStyles(colorForBackground, fontFam)();

  return (
    <Link className={classes.links} to={`/genre/${genre}`}>
      <Box
        className={classes.container}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography align="center" variant="h4" className={classes.textStyle}>
          {genre}
        </Typography>
      </Box>
    </Link>
  );
};

export default GenreItem;
