import React, { FC } from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router";

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    fill: "white",
  },
  text: { color: "white", marginLeft: "15px" },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

interface IBackButtonWithTextProps {
  title: string;
}

const BackButtonWithText: FC<IBackButtonWithTextProps> = ({ title }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <div className={classes.container}>
      <IconButton onClick={() => history.goBack()}>
        <KeyboardBackspaceIcon className={classes.icon} />
      </IconButton>

      <Typography className={classes.text} variant="h3">
        {title}
      </Typography>
    </div>
  );
};

export default BackButtonWithText;
