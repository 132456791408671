import { useQuery } from "@apollo/react-hooks";
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "../core/graphql/new/queries/getCurrentUser";

import AccountBalanceChart from "components/AccountBalanceChart";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import UserTransactions from "containers/UserTransactions";
import StarfarmLogo from "components/@ui/StarfarmLogo";
import AccountBalance from "containers/AccountBalance";
import { makeStyles, Theme } from "@material-ui/core/styles";
import BackButtonWithText from "components/@ui/BackButtonWithText";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import GET_USER_TRANSACTIONS, {
  GET_USER_TRANSACTIONSResponse,
} from "core/graphql/new/queries/getUserTransactions";
import GET_USER_TIPS, {
  GET_USER_TIPSResponse,
} from "core/graphql/new/queries/getUserTips";
import CheckBoxWithInfo from "components/@ui/CheckBoxWithInfo";
import { useHistory } from "react-router-dom";
import AutoplayButton from "components/@ui/AutoplayButton";
import VolumeControls from "components/Player/VolumeControls";
import { ContactSupportOutlined } from "@material-ui/icons";

interface ITheme {
  urlHash?: boolean;
}

const useStyles = makeStyles<Theme, ITheme>((theme) => ({
  AccountBalance: {
    color: "white",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  icon: {
    fill: "white",
  },
  recentTransactionsButtonText: { color: "#56C2BC", fontWeight: "bold" },
  recentTransactionsButtonContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: "15px 10px",
    border: "solid 1px white",
    borderRadius: "10px",
    margin: "25px 0px",
  },
  contentContainer: {
    padding: "25px",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      height: "90vh",
      maxHeight: "90vh",
      overflow: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      height: (props) => props.urlHash === true && "90vh",
      maxHeight: (props) => props.urlHash === true && "90vh",
      overflow: (props) => props.urlHash === true && "hidden",
    },
    [theme.breakpoints.down("md")]: {
      padding: "25px 25px 150px",
      justifyContent: "center",
    },
  },
  mainGrid: {
    height: "100%",
    maxWidth: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  secondGrid: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "600px",
    },
  },
  button: {
    backgroundColor: "#FD4060",
    padding: "5px",
    width: "100%",
    borderRadius: "100px",
    marginTop: "20px",
  },
  volume: { marginTop: "20px" },
}));

interface IAccountProps { }

const Account: FC<IAccountProps> = (props) => {
  const { data } = useQuery<GET_CURRENT_USERResponse>(GET_CURRENT_USER, {
    pollInterval: 1000,
  });


  




  const history = useHistory();


  const {
    data: transactionData,
    loading,
  } = useQuery<GET_USER_TRANSACTIONSResponse>(GET_USER_TRANSACTIONS, {
    fetchPolicy: "network-only"
  });

  const {
    data: tipData,
    loading:loading1,
  } = useQuery<GET_USER_TIPSResponse>(GET_USER_TIPS, {
    fetchPolicy: "network-only"
  });


  let tab = +window.location.search.split("=")[1]
  const urlHash = window.location.hash;


  const classes = useStyles({ urlHash: !!!urlHash });


  return (
    <PageWithBackgroundImage>
      <Grid className={classes.contentContainer} container>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          className={classes.mainGrid}
          item
        >
          <Box
            display="flex"
            alignItems="center"
            mb={3}
            justifyContent="space-between"
          >
            <Typography style={{ color: "white" }} variant="h3">
              Account
            </Typography>

            <Typography variant="subtitle2">
              {data?.currentUser?.email}
            </Typography>
          </Box>

          <Hidden mdUp={true}>
            <Box width="100%" display="flex" justifyContent="center">
              <StarfarmLogo size={75} />
            </Box>
          </Hidden>

          <Hidden lgUp={true}>
            {window.location.hash === "#1" ? (
              <BackButtonWithText title="Recent Transactions" />
            ) : window.location.hash === "#2" ? (
              <BackButtonWithText title="All Transactions" />
            ) : (
              <>
                <Typography gutterBottom variant="subtitle2">
                  Balance
                </Typography>
                <AccountBalance variant="h1" style={classes.AccountBalance} />

                <div
                  onClick={() => (window.location.hash = "#1")}
                  className={classes.recentTransactionsButtonContainer}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.recentTransactionsButtonText}
                  >
                    Recent Transactions
                  </Typography>
                  <ArrowRightAltIcon className={classes.icon} />
                </div>
              </>
            )}
          </Hidden>

          <Hidden mdUp={true}>
            <Typography
              className={classes.volume}
              variant="subtitle1"
              gutterBottom
            >
              Playback Volume:
            </Typography>

            <VolumeControls horizontal />
          </Hidden>

          <Hidden mdDown={window.location.hash === "#1" ? false : true}>
            <AccountBalanceChart
              loading={loading}
              transactions={transactionData?.transactions}
            />
          </Hidden>

          <Hidden mdDown={window.location.hash ? true : false}>
            <CheckBoxWithInfo
              title="Automatic Reloads"
              info="Never let your balance drop to 0 Star, we’ll reload your account to your chosen price whenever your funds are running short that month so you can always stay connected to your music."
            />

            <Box mt={2} display="flex" width="100%" alignItems="center">
              <Box display="flex" width="84%" alignItems="center" mr={4}>
                <AutoplayButton />
                <Typography variant="subtitle1"> : Auto Play</Typography>
              </Box>
              <Typography style={{ width: "115%" }} variant="body2">
                Enabling auto play will cause your music to automatically jump
                to the next song in your queue after a song ends, creating an
                uninterrupted listening experience.
              </Typography>
            </Box>

            <Button
              onClick={() => history.push("/payment")}
              className={classes.button}
            >
              Add Funds
            </Button>
          </Hidden>
        </Grid>

        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          className={classes.secondGrid}
          item
        >
          <Hidden mdDown={window.location.hash ? false : true}>
            <UserTransactions
              loading={loading}
              tab= {tab}
              transactions={tab === 1 ? transactionData?.transactions : tipData?.transactions}
            />
          </Hidden>
        </Grid>
      </Grid>
    </PageWithBackgroundImage>
  );
};

export default Account;
