import gql from "graphql-tag";

const ALL_COUNTRIES = gql`
  query {
    getAllCountries {
      name
      alpha2Code
      payoutCurrency
    }
  }
`;

export interface COUNTRIES_RESPONSE {
  name: any;
  alpha2Code: any;
  payoutCurrency: any;
}

export interface COUNTRIES_VARIABLES {
  name: string;
  alpha2Code: string;
  payoutCurrency: string;
}

export default ALL_COUNTRIES;
