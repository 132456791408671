import React, { FunctionComponent, useMemo, useState, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withWidth, {
  WithWidth,
  isWidthDown,
} from "@material-ui/core/withWidth/withWidth";
import Box from "@material-ui/core/Box/Box";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const removeScrollUnit = 20;

const useStyles = makeStyles({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  gridList: {
    overflowY: "hidden",
    height: `calc(100% + ${removeScrollUnit}px)`,
    overflowX: "scroll",
    flex: 1,
    whiteSpace: "nowrap",
    paddingTop: 3,
    paddingLeft: 3,
    WebkitOverflowScrolling: "touch",
    transform: "translateZ(0)",

    paddingBottom: 2,
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  pointerRight: {
    position: "absolute",
    cursor: "pointer",
    right: 0,
    top: 0,
    height: "100%",
    width: 100,
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(270deg, #02172a,#02172af5, #02172a0d)",

    "& svg": {
      fontSize: 45,
    },
  },

  pointerLeft: {
    position: "absolute",
    cursor: "pointer",
    left: 0,
    top: 0,
    height: "100%",
    width: 100,
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(90deg, #02172a,#02172af5, #02172a0d)",

    "& svg": {
      fontSize: 45,
    },
  },
});

interface IProps extends WithWidth {
  children?: React.ReactNode;
  id: string;
  title?: JSX.Element;
}

const SingleLineGridList: FunctionComponent<IProps> = ({
  children,
  id,
  width,
  title = <div />,
  ...otherProps
}) => {
  const isMobile = useMemo(() => isWidthDown("sm", width), [width]);

  const classes = useStyles();

  const [hasScrollLeft, setHasScrollLeft] = useState(false);

  const [hasScrollRight, setHasScrollRight] = useState(false);

  useEffect(() => {
    const inner = document.getElementById(id);

    if (inner === null) {
      return;
    }

    const parent = inner.parentElement;

    if (!parent) {
      return;
    }

    if (Math.abs(inner.scrollWidth - parent.scrollWidth) > 10) {
      setHasScrollRight(true);
    }
  }, [children, id]);

  const handleScroll = (scrollRight?: boolean) => {
    const inner = document.getElementById(id);

    if (inner === null) {
      return;
    }

    const parent = inner.parentElement;

    if (!parent) {
      return;
    }

    let i = 0;

    const myInt = setInterval(() => {
      if (i >= 100) {
        clearInterval(myInt);
        return;
      }

      if (scrollRight) {
        inner.scrollLeft = inner.scrollLeft + parent.scrollWidth / 125;
      } else {
        inner.scrollLeft = inner.scrollLeft - parent.scrollWidth / 125;
      }

      if (inner.scrollLeft === 0) {
        setHasScrollLeft(false);
      } else {
        setHasScrollLeft(true);
      }

      if (
        Math.abs(inner.scrollLeft + parent.scrollWidth - inner.scrollWidth) < 5
      ) {
        setHasScrollRight(false);
      } else {
        setHasScrollRight(true);
      }

      i++;
    }, 2);
  };

  const handleLeftScroll = () => {
    handleScroll();
  };

  const handleRightScroll = () => {
    handleScroll(true);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        {title}
      </Box>

      <div {...otherProps} className={classes.root}>
        <Box>
          {Boolean(hasScrollLeft && !isMobile) && (
            <Box
              className={classes.pointerLeft}
              px={1}
              onClick={handleLeftScroll}
            >
              <ChevronLeft />
            </Box>
          )}
        </Box>

        <div id={id} className={classes.gridList}>
          <Box px={1} display="inline-block" height={1} />

          {children}
        </div>

        <Box>
          {Boolean(hasScrollRight && !isMobile) && (
            <Box
              className={classes.pointerRight}
              px={1}
              onClick={handleRightScroll}
            >
              <ChevronRight />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

export default withWidth()(SingleLineGridList);
