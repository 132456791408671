import gql from "graphql-tag";

const setStripePaymentMethod = gql`
  mutation setStripePaymentMethod($paymentMethodId: String!) {
    setStripePaymentMethod(paymentMethodId: $paymentMethodId) {
      _id
    }
  }
`;

export interface setStripePaymentMethodResponse {
  user: NewUser;
}

export interface setStripePaymentMethodVariables {
  paymentMethodId: string;
}

export default setStripePaymentMethod;
