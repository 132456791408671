import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import starFarmLogo from "picture/starFarm.png";
import HorizontalRule from "../Desktop/components/HorizontalRule";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  textfield: {
    width: "260px",
    height: "40px",
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    "& .MuiInput-root": {
      width: "100%",
      height: "100%",
      borderRadius: "100px",
    },
    "& .MuiInput-underline": {
      "&:before": {
        width: "0%",
      },
      "&:after": {
        width: "90%",
        left: "5%",
      },
    },
  },
  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    "&:focus": {
      textDecoration: "none",
    },
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontFamily: "simplifica",
    fontSize: "40px",
  },
  subtitle: { color: "rgba(84, 186, 180, 0.5)", marginBottom: "40px" },
  loginButton: {
    marginTop: "40px",
    backgroundColor: "#66C6B2",
    width: "205px",
    height: "40px",
    borderRadius: "25px",
    color: "#02172A",
  },
  createAccountButton: {
    marginTop: "30px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    width: "205px",
    height: "40px",
    borderRadius: "25px",
    color: "rgba(255, 255, 255, 0.5)",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#fff",
    marginTop:"20px",
    fontSize:'14px',
    "&:hover":{
      color: "rgba(84, 186, 180, 0.5)"
    }
  },
});

const inputProps = {
  min: 0,
  style: {
    textAlign: "center",
    padding: "0px",
    height: "100%",
    borderRadius: "100px",
  },
};

const starFarmBackGround = {
  backgroundImage: `url(${starFarmLogo})`,
  width: "180px",
  height: "180px",
  backgroundSize: "cover",

  backgroundRepeat: "no-repeat",
} as React.CSSProperties;

interface LoginMobile {
  handleSubmit: (email: string, password: string) => void;
}

const LoginMobile: FC<LoginMobile> = ({ handleSubmit }) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(email, password);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.contentContainer}>
          <Box style={starFarmBackGround} />

          <Typography variant="h2" className={classes.title}>
            StarFarm.
          </Typography>

          <Typography variant="h4" className={classes.subtitle}>
            We Grow Stars.
          </Typography>
        </Box>

        <Box>
          <form onSubmit={handleSubmitForm}>
            <TextField
              value={email}
              onChange={handleEmailChange}
              placeholder="email@email.com"
              type="email"
              inputProps={inputProps as any}
              className={classes.textfield}
            />

            <HorizontalRule />

            <TextField
              value={password}
              onChange={handlePasswordChange}
              placeholder="password"
              type="password"
              inputProps={inputProps as any}
              className={classes.textfield}
            />

            <HorizontalRule />

            <Box className={classes.buttonContainer}>
              <Button type="submit" className={classes.loginButton}>
                Log In
              </Button>
            </Box>
          </form>

          <Box className={classes.buttonContainer}>
            <Link to="/registration" className={classes.link}>
              <Button type="button" className={classes.createAccountButton}>
                Create An Account
              </Button>
            </Link>
          </Box>
          <Box className={classes.buttonContainer}>
          <Link to="/forgot-password" className={classes.forgotPasswordLink}>
            Forgot Password?
          </Link>
        </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginMobile;
