import gql from "graphql-tag";

const GET_CURRENT_USER = gql`
  query {
    currentUser: getCurrentUser {
      _id
      email
      balanceInPennies
      automaticReload
      autoPlaySongs
      pendingNotification
    }
  }
`;

export interface GET_CURRENT_USERResponse {
  currentUser?: NewUser;
}

export interface GET_CURRENT_USERVariables {}

export default GET_CURRENT_USER;
