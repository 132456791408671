import IconButton from "@material-ui/core/IconButton/IconButton";
import Popover from "@material-ui/core/Popover/Popover";
import Slider from "@material-ui/core/Slider/Slider";
import VolumeButton from "components/@ui/VolumeButton";
import usePlayer from "context/PlayerContext";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
const useStyles = makeStyles<Theme>((theme) => ({
  Popover: {
    "& .MuiPopover-paper": {
      padding: "15px 0px 10px",
      overflow: "hidden",
    },
  },
}));

interface IVolumeControlsProps {
  horizontal?: boolean;
}

const VolumeControls: FC<IVolumeControlsProps> = ({ horizontal = false }) => {
  const classes = useStyles();

  const player = usePlayer();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (horizontal) {
    return (
      <Box display="flex" alignItems="center" mb={5}>
        <VolumeButton />

        <Slider
          onChangeCommitted={(e, v) => player.setVolume((v as number) / 100)}
          onChange={(e, v) => player.setVolume((v as number) / 100)}
          min={0}
          max={100}
          style={{ width: "75%", maxWidth: "250px", marginLeft: "20px" }}
          value={player.volume * 100 || 1}
        />
      </Box>
    );
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <VolumeButton />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.Popover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Slider
          onChangeCommitted={(e, v) => player.setVolume((v as number) / 100)}
          onChange={(e, v) => player.setVolume((v as number) / 100)}
          min={0}
          orientation="vertical"
          max={100}
          style={{ height: "150px" }}
          value={player.volume * 100 || 1}
        />
      </Popover>
    </>
  );
};

export default observer(VolumeControls);
