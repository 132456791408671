import React, { FC } from "react";
// import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid/Grid";
import { makeStyles, createStyles, Hidden, Theme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import { Pagination } from '@material-ui/lab';
import findUserNotification, {
    findUserNotificationResponse,
    findUserNotificationVariables
} from "../../core/graphql/queries/findUserNotification"
import GET_CURRENT_USER, {
    GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles<Theme>((theme) =>
    createStyles({
        track: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#071C2D",
            padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(
                2
            )}px  ${theme.spacing(2)}px `,

            marginBottom: theme.spacing(1),
            borderRadius: 6,
            cursor: "pointer",
            "&&:hover": {
                backgroundColor: "#041523",
            },
        },
        songName: {
            width:"300px",
            textOverflow: "ellipsis",
            color: (props) =>
                theme.palette.secondary.main
        },
        mainContainer: {
            padding: "20px 50px"
        },
        header: {
            fontSize: "20px"
        }
    })
);

const Notification: FC = () => {

    const [id, setId] = React.useState<string | undefined>("")
    const [page, setPage] = React.useState(parseInt(window.location.search.split("=")[1]??1));
    const [count, setCount] = React.useState(0)
    const history = useHistory()

    const limit = 10

    // console.log(window.location.search,'page')

    const { data, refetch } = useQuery<findUserNotificationResponse, findUserNotificationVariables>(
        findUserNotification,
        { variables: { id: id, limit: limit, offset: limit * page - limit } }
    );
    const { data: userData } = useQuery<GET_CURRENT_USERResponse>(
        GET_CURRENT_USER,
    );

    React.useEffect(() => {
        getNotification()
        if (data && data.findUserNotifications.total % 10 > 0) {
            setCount(Math.floor(data ? data.findUserNotifications.total / 10 : 1) + 1)
          }
          else {
            setCount(Math.floor(data ? data.findUserNotifications.total / 10 : 1))
          }
      
        // refetch()
    }, [userData, data])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        history.push({
            search: `?page=${value}`
        })
    };

    const getNotification = () => {
        setId(userData?.currentUser ? userData?.currentUser?._id : undefined)
        // console.log(data,'sds')
    }

    console.log(id)

    const handleClick = (id: string) => {
        history.push(`/album/${id}`)
    }


    const classes = useStyles()

    return (
        <>
            <div className={classes.mainContainer}>
                <div className={classes.header}>
                    <h2>Notifications
                    </h2>
                </div>
                {data?.findUserNotifications && data?.findUserNotifications.notifications.length>0 ? <>
                    {data?.findUserNotifications?.notifications && data?.findUserNotifications?.notifications.map((item: any, idx: number) => (
                        <Grid className={classes.track} container={true} 
                        onClick={()=>handleClick(data?.findUserNotifications?.notifications[idx].albumId)}
                        >
                            <Grid container item xs={12}>
                                <Grid item xs={2} md={2}>
                                    <Typography color="primary" noWrap>
                                        {((page - 1) * 10) + idx+1}
                                    </Typography>
                                </Grid>

                                <Grid item xs={10} md={4}>
                                    <Typography className={classes.songName} noWrap>
                                        {item.body}
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography noWrap>{item.title}</Typography>
                                </Grid>
                                <Grid item xs={10} md={2}
                                >
                                    <Typography noWrap> {item.type}</Typography>

                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Pagination count={count} page={page} onChange={handleChange} style={{ marginTop: '20px' }} />
                </> : <>
                    <h2>
                        No Notification
                    </h2>
                </>}

            </div>
        </>
    )
}

export default Notification