import React, { FC } from "react";

import { formatDistanceToNow, parseJSON } from "date-fns";
import { useMemo } from "react";
import Typography from "@material-ui/core/Typography/Typography";

interface ITimePastFromNowProps {
  createdAt?: string;
}

const TimePastFromNow: FC<ITimePastFromNowProps> = ({ createdAt }) => {
  const formatedDate = useMemo(() => {
    if (!createdAt) {
      return undefined;
    }

    const parsed = parseJSON(createdAt);

    const formated = formatDistanceToNow(parsed);

    return formated;
  }, [createdAt]);

  if (!createdAt) {
    return <></>;
  }

  return (
    <Typography variant="body2" align="right">
      {`${formatedDate} ago` || "recent"}
    </Typography>
  );
};

export default TimePastFromNow;
