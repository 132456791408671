import Button from "@material-ui/core/Button/Button";
import React, { FC, useState, useContext } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Dialog, Typography } from "@material-ui/core";
import Box from "components/@ui/Box";
import starfarmLogo from "../../picture/starFarmLarge.png";
import shareButton from "../../picture/share.svg"
import { WhatsappShareButton, EmailShareButton, FacebookShareButton, LinkedinShareButton, RedditShareButton, PinterestIcon, PinterestShareButton, TwitterShareButton, EmailIcon, FacebookIcon, RedditIcon, TwitterIcon, WhatsappIcon, LinkedinIcon, TumblrShareButton, TumblrIcon, TelegramShareButton, TelegramIcon } from "react-share";
// import { colors } from "fonts/colors";
import TokenManager from "core/auth/TokenManager";
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface StyleProps {
    image?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    playButton: { borderRadius: 80, width: 100 },
    shareButton: {
        width: "30px",
        height: "30px",
        color: "white",
    },
    shareLink: {
        width: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    },
    dialogPaper: {
        margin: 0,
        backgroundColor: "#061c2d",
        maxWidth: "700px",
        width: "65%",
        height: "65%",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-evenly",
        paddingTop: "10px",
        paddingBottom: "10px",
        position: "relative",
    },
    modalHeader: {
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "2%",
    },
    donateButton: {
        width: "80%",
        borderRadius: 100,
        background: "#3D7670",
        color: "white",
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px",
        },
    },
    addFundsButton: {
        width: "80%",
        borderRadius: 100,
        borderColor: "white",
        backgroundColor: "#2C3E4D",
        border: "1px solid white",
        "&:hover": {
            cursor: "pointer",
        },
    },
    image: {
        backgroundImage: (props) => `url(${props.image})`,
        height: "180px",
        width: "180px",
        justifyContent: "space-evenly",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "none",
    },
    copyLink: {
        // background: 'black',
        padding: "10px",
    },
    copy: {

        paddingLeft: "10px",
        color: "#BDBDBD",
        "&:hover": {
            cursor: "pointer",
        },
    }
}));

interface IPlayButtonProps {
    image?: string;
    url?: string;
    title?: string;
}

const ShareButton: FC<IPlayButtonProps> = ({ image, url, title }) => {
    const [messageOpen, setMessageOpen] = useState(false);

    const manager = useContext(TokenManager);
    const token = manager.getToken();




    // const alertManager = useContext(EventMessageContext);

    // const [setAuto] = useMutation<setAutoPlayResponse, setAutoPlayVariables>(
    //     setAutoPlay,
    //     {
    //         refetchQueries: [{ query: GET_CURRENT_USER }],
    //     }
    // );
    // const player = usePlayer();


    // const currentSongIndex = player.currentSongOrder.findIndex(
    //     (s) => s === player.currentSong
    // );


    const classes = useStyles({ image: image || starfarmLogo });

    // const url = window.location.href


    const handleShare = () => {
        if (token === 'undefined') {
            window.location.href = "/login"
        }
        else {
            setMessageOpen(true)
        }

    }

    // const playSongs = (force?: boolean) => {
    //     if (!songs) {
    //         return;
    //     }

    //     if (!player.autoPlay && force === undefined) {
    //         setMessageOpen(true);
    //         return;
    //     }
    //     if (currentSongIndex + 1 === 0) {
    //         player.currentSong = player.currentSongOrder[currentSongIndex];
    //     }

    //     const songIds = songs.map((song) => song._id);
    //     player.selectSong(songIds[0], songIds);
    // };

    // const setAutoPlayClick = async () => {
    //     player.setAutoPlay(!player.autoPlay);

    //     setMessageOpen(false);

    //     playSongs();

    //     alertManager.setEvent("success", "", `Auto Play has been enabled`);

    //     await setAuto({
    //         variables: {
    //             autoPlay: player.autoPlay,
    //         },
    //     });
    // };

    return (
        <>
            <Button onClick={() => handleShare()}>
                <img className={classes.shareButton} src={shareButton} alt="img" />
            </Button>
            {/* <Button
                color="primary"
                className={classes.playButton}
                variant="contained"
                onClick={() => playSongs()}
            >
                SHARE
            </Button> */}

            <Dialog
                open={messageOpen}
                onClose={() => setMessageOpen(false)}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <Box className={classes.contentContainer}>
                    <Box className={classes.modalHeader}>
                        <Typography variant="h2">Share With Someone</Typography>
                    </Box>


                    {/* <Typography variant="body1"> */}
                    <Typography className={classes.copyLink}>{url}<CopyToClipboard text={url!}>
                        <span className={classes.copy}>Copy</span>
                    </CopyToClipboard></Typography>

                    <Box className={classes.image} />

                    <Typography variant="h3">{title}</Typography>
                    {/* </Typography> */}



                    {/* <Slider {...settings}> */}
                    <div className={classes.shareLink}>
                        <WhatsappShareButton url={url ?? ""} >
                            <WhatsappIcon size={60} round={true}></WhatsappIcon>
                        </WhatsappShareButton>

                        <EmailShareButton url={url ?? ""}>
                            <EmailIcon size={60} round={true}></EmailIcon>
                        </EmailShareButton>

                        <FacebookShareButton url={url ?? ""}>
                            <FacebookIcon size={60} round={true}></FacebookIcon>
                        </FacebookShareButton>

                        <LinkedinShareButton url={url ?? ""}>
                            <LinkedinIcon size={60} round={true}></LinkedinIcon>
                        </LinkedinShareButton>

                        <TwitterShareButton url={url ?? ""}>
                            <TwitterIcon size={60} round={true}></TwitterIcon>
                        </TwitterShareButton>

                        <RedditShareButton url={url ?? ""}>
                            <RedditIcon size={60} round={true}></RedditIcon>
                        </RedditShareButton>
                        <PinterestShareButton url={url ?? ""} media={url ?? ""}>
                            <PinterestIcon size={60} round={true}></PinterestIcon>
                        </PinterestShareButton>
                        <TumblrShareButton url={url ?? ""}>
                            <TumblrIcon size={60} round={true}></TumblrIcon>
                        </TumblrShareButton>
                        <TelegramShareButton url={url ?? ""}>
                            <TelegramIcon size={60} round={true}></TelegramIcon>
                        </TelegramShareButton>
                    </div>
                    {/* </Slider> */}


                    {/* <Grid justify="space-between" container>
                        <Grid item md={6} lg={6} xs={12} sm={6}>
                            <Button
                                onClick={() => {
                                    playSongs(true);
                                    setMessageOpen(false);
                                }}
                                className={classes.addFundsButton}
                            >
                                Ignore
                            </Button>
                        </Grid>

                        <Grid item md={6} lg={6} sm={6} xs={12}>
                            <Button
                                onClick={setAutoPlayClick}
                                className={classes.donateButton}
                            >
                                Auto Play
                            </Button>
                        </Grid>
                    </Grid> */}
                </Box>
            </Dialog>
        </>
    );
};

export default ShareButton;
