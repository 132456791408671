import Box from "@material-ui/core/Box/Box";
import Slider from "@material-ui/core/Slider/Slider";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import usePlayer from "context/PlayerContext";
import convertTime from "jobs/convertTime";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";

interface SliderProps {
  hideTimers?: boolean;
  horizontal?: boolean;
}

interface UseStlyesParams {
  hideTimers: boolean;
  horizontal: boolean;
}

const useStyles = ({ hideTimers }: UseStlyesParams) =>
  makeStyles((theme) =>
    createStyles({
      mainContainer: {
        display: hideTimers ? "flex" : "inline-block",
        alignItems: "center",
        width: "100%",
      },
      timerContainer: {
        width: hideTimers ? "5%" : "100%",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        marginLeft: hideTimers ? "10px" : "0px",
      },
      text: {
        fontSize: hideTimers ? "11px" : "inherit",
        color: hideTimers ? "rgba(255,255,255,0.75)" : "inherit",
      },
    })
  );

const MusicPlayerSlider: FC<SliderProps> = ({
  hideTimers = false,
  horizontal = false,
}) => {
  const player = usePlayer();
  const classes = useStyles({ hideTimers, horizontal })();
  const currentTime = convertTime(player.currentTime || 0);
  const remainingTime = convertTime(player.timeRemaining || 0);
  const [slide, valS] = useState(0)
  const [remainSlide, remainValS] = useState(0)
  const [slideMove, isSlideMove] = useState(false)
  const sliderVal = convertTime(slide || 0)
  const remainingSliderVal = convertTime(remainSlide || 0)


  // const currentSongIndex = player.currentSongOrder.findIndex(
  //   (s) => s === player.currentSong
  // );

  return (
    <Box className={classes.mainContainer}>
      {horizontal === false && (
        <Slider
          onChangeCommitted={(e, v) => player.seek(v as number)}
          onChange={(e, v) => (player.currentTime = v as number)}
          min={0}
          max={player.songDuration || 0}
          value={player.currentTime || 0}
          color="primary"
        />
      )}

      <Box className={classes.timerContainer}>
        <Typography
          color="textSecondary"
          variant="caption"
          className={classes.text}
        >
          {slideMove ? sliderVal ?? "0:00" : currentTime || "0:00"}
          {/* {slideMove ? sliderVal : currentTime || "0:00"} */}

        </Typography>

        {horizontal && (
          <Slider
            onChangeCommitted={(e, v) => {
              player.play()
              player.seek(v as number)
              if (player.songDuration ? v === player.songDuration ?? 0 : 0) {
                player.next()
              }
              setTimeout(() => {
                isSlideMove(false)
              }, 500)

            }
            }
            onChange={(e, v: any) => {
              player.pause()
              isSlideMove(true)
              valS(player.currentTime = v as number)
              const evaluatedTime = player.songDuration ? player.songDuration - v : 0
              remainValS(evaluatedTime)
            }}
            min={0}
            style={{ width: "80%" }}
            max={player.songDuration || 0}
            value={slideMove ? slide : player.currentTime || 0}
            color="primary"
          />
        )}

        {hideTimers === false && (
          <Typography
            color="textSecondary"
            variant="caption"
            className={classes.text}
          >
            {`-${slideMove ? remainingSliderVal ?? "0:00" : remainingTime || "0:00"}`}
            {/* {`-${slideMove ? remainingSliderVal : remainingTime || "0:00"}`} */}

          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default observer(MusicPlayerSlider);
