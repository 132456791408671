import React, { FC } from "react";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface FeedbackModalProps {
  failed?: boolean;
  handleClose: (e: any) => void;
}

const FeedbackModal: FC<FeedbackModalProps> = ({ failed, handleClose }) => {
  const styles = useStyles()();
  return (
    <Box className={styles.container}>
      <Box className={styles.closeButtonContainer}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {failed ? (
        <Box className={styles.content}>
          <Box className={styles.title}>
            <ErrorOutlineIcon />
            <Typography variant="h4" className={styles.titleName}>
              Oops something went wrong.
            </Typography>
          </Box>

          <Typography>
            Something went wrong while trying to submit your report. Please try
            again later.
          </Typography>
        </Box>
      ) : (
        <Box className={styles.content}>
          <Box className={styles.title}>
            <CheckCircleOutlineIcon />
            <Typography variant="h4" className={styles.titleName}>
              Report submitted successfully
            </Typography>
          </Box>

          <Typography>
            We will work on resolving the issue. Thank you for alerting us.
          </Typography>
        </Box>
      )}

      <Button variant="contained" onClick={handleClose}>
        Done
      </Button>
    </Box>
  );
};

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      closeButtonContainer: {
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        display: "flex",
        paddingRight: 10,
      },
      container: {
        width: "100%",
        height: "100%",
        paddingTop: 20,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      },
      content: {
        display: "flex",
        flexDirection: "column",
        width: 350,
      },
      title: {
        display: "flex",
        flexDirection: "row",
        width: 350,
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: 10,
      },
      titleName: {
        marginLeft: 10,
      },
    })
  );

export default FeedbackModal;
