import React from "react";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { observer } from "mobx-react-lite";

interface Props {
  songPlay: boolean;
  small?: boolean;
}

const SongPlayIcon: React.FC<Props> = ({ songPlay, small = false }) => {
  if (songPlay === true) {
    return (
      <PauseCircleFilledIcon
        style={{ fontSize: small ? "40px" : "6vh", fill: "#b74260" }}
      />
    );
  }

  return (
    <PlayCircleFilledWhiteIcon
      style={{ fontSize: small ? "40px" : "6vh", fill: "#b74260" }}
    />
  );
};

export default observer(SongPlayIcon);
