import React, { FC, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import MoreButton from "./MoreButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import AddToPlaylistDialog from "../AddToPlaylistDialog";
import RemoveSongFromPlaylistButton from "../RemoveSongFromPlaylistButton";
import TipArtistModal from "../TipArtistModal";
import ReportFormContext from "stores/ReportFormStore";
import { observer } from "mobx-react-lite";
import TokenManager from "core/auth/TokenManager";
import { Box } from "@material-ui/core";
interface PlaylistMoreButtonProps {
  artistId?: string;
  albumId?: string;
  songId?: string;
  playlistMode?: boolean;
  playlistId?: string;
  artistName?: string;
  artistImage?: string;
}

const linkStyle = {
  textDecoration: "none",
  color: "rgba(255,255,255,0.75)",
};

const PlaylistMoreButton: FC<PlaylistMoreButtonProps> = observer(
  ({
    artistId,
    albumId,
    songId,
    playlistMode = false,
    playlistId,
    artistName,
    artistImage,
  }) => {
    const store = useContext(ReportFormContext);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setAnchorEl((e as any).currentTarget);
    };
    const manager = useContext(TokenManager);
    const token = manager.getToken();

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenReportModal = () => {
      if (token === "undefined") {
        window.location.href = "/login";
      } else {
        store.openModal();
        store.setType("song");
        if (songId) {
          store.setId(songId);
        }
      }
    };

    const StyledMenuItem = {
      padding: "6px 10px",
      color: "rgba(255, 255, 255, 0.75)",
      cursor: "pointer",
    };

    return (
      <>
        <MoreButton
          anchor={anchorEl}
          setOpen={handleClick}
          setClose={setAnchorEl}
          dropDownIcon={<MoreHorizIcon />}
        >
          <ListItemText primary="" />

          <Box
            className="styledItemHoverClass"
            style={StyledMenuItem}
            onClick={handleClose}
          >
            <Link style={linkStyle} to={`/album/${albumId}`}>
              <ListItemText primary="Go To Album" />
            </Link>
          </Box>

          <Box
            className="styledItemHoverClass"
            style={StyledMenuItem}
            onClick={handleClose}
          >
            <Link style={linkStyle} to={`/artist/${artistId}`}>
              <ListItemText primary="Go To Artist" />
            </Link>
          </Box>

          {playlistMode === true && (
            <Box
              className="styledItemHoverClass"
              style={StyledMenuItem}
              onClick={handleClose}
            >
              <RemoveSongFromPlaylistButton
                songId={songId}
                playlistId={playlistId}
              />
            </Box>
          )}

          <Box className="styledItemHoverClass" style={StyledMenuItem}>
            <TipArtistModal
              menuItem
              artistId={artistId}
              artistImage={artistImage}
              artistName={artistName}
            />
          </Box>

          <Box className="styledItemHoverClass" style={StyledMenuItem}>
            <AddToPlaylistDialog closeMenu={handleClose} songId={songId} />
          </Box>

          <Box
            className="styledItemHoverClass"
            style={StyledMenuItem}
            onClick={handleOpenReportModal}
          >
            <ListItemText primary="Report" />
          </Box>
        </MoreButton>
      </>
    );
  }
);

export default PlaylistMoreButton;
