import React from "react";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";

import ModalControls from "./ModalControls";
import FavoriteSong from "containers/FavoriteSong";
import MusicPlayerSlider from "containers/MusicPlayerSlider";
import AutoplayButton from "components/@ui/AutoplayButton";

const useStyles = (image?: string) =>
  makeStyles((theme) =>
    createStyles({
      albumArt: {
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        margin: "0 0 0.5em 0",
        width: "30vh",
        overflow: "visible",
        borderRadius: "8%",
        height: "30vh",
        zIndex: 9999,
        maxHeight: 200,
        maxWidth: 200,
        marginBottom: 24,
      },
      mainContainer: {
        width: "75%",
        maxHeight: "100%",
        height: "100%",
      },
      contentContainer: {
        height: "60%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
      },
      titleSeperator: {
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
    })
  );

interface FocusedSongModalProps {
  title?: string;
  artist?: string;
  album?: string;
  art?: string;
  id?: string;
}

const FocusedSongModal: React.FC<FocusedSongModalProps> = ({
  title,
  artist,
  album,
  art,
  id,
}) => {
  const classes = useStyles(art)();

  return (
    <>
      <Box className={classes.mainContainer}>
        <Box className={classes.contentContainer}>
          <Box className={classes.albumArt}></Box>
          <Typography color="textSecondary" variant="caption">
            Now Playing
          </Typography>

          <Box margin="1.5vh" className={classes.titleSeperator}>
            <Typography color="textSecondary" variant="h6">
              {title}
            </Typography>
          </Box>

          <Box mb={2} className={classes.titleSeperator}>
            <Typography
              gutterBottom={true}
              color="textSecondary"
              variant="caption"
            >
              {artist} - {album}
            </Typography>
          </Box>
        </Box>

        <ModalControls />

        <MusicPlayerSlider />

        <Box mt={3} className={classes.buttonContainer}>
          <AutoplayButton />
          <FavoriteSong songId={id} />
        </Box>
      </Box>
    </>
  );
};

export default FocusedSongModal;
