import React, { FC } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import { Button } from "@material-ui/core";
import starfarmLogo from "../../picture/starFarmLarge.png";

interface IThemeProps {
  mr: number;
  active: boolean;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) => ({
  container: {
    position: "relative",
    width: "45%",
    maxWidth: "220px",
    background: "#2e7362",
    border: "2px solid #2e7362",
    height: "fit-content",
    borderRadius: "20px",
    marginRight: (props) => props.mr,
    marginBottom: "24px",
  },
  box: {
    width: "100%",
    padding: "25px",
    backgroundColor: (props) => (props.active === true ? "#2e7362" : "#02172A"),
    borderRadius: "20px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2e7362",
    },
  },
  banner: {
    width: "100%",
    padding: "2px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
  modalButton: {
    width: "80%",
    textAlign: "center",
    backgroundColor: "#3D7670",
    color: "white",
    padding: "6px 10px",
    borderRadius: "8px",
    marginTop: "25px",
    fontSize: "16px",
    zIndex: 2,
  },
  cardContainer: {
    position: "relative",
    // border: "1px solid red",
    backgroundColor: "#082338",
    width: "250px",
    padding: "10px",
    height: "auto",
    // padding: "20px 40px 0",
    // background: `url('${starfarmLogo}')`,
    // backgroundPosition: "center",
    // backgroundSize: "100%",
    // backgroundRepeat: "no-repeat",
  },
  image: {
    width: "40%",
    height: "auto",
    position: "absolute",
    top: "20%",
    textAlign: "center",
    margin: "0 auto",
    left: "0",
    right: "0",
    zIndex: 1,
    opacity: "10%",
  },
}));

interface IPaymentAmountSelectProps {
  stars: number;
  price: number;
  banner?: string;
  mr?: number;
  active?: boolean;
  selectPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const PaymentAmountSelect: FC<IPaymentAmountSelectProps> = ({
  price,
  stars,
  banner,
  mr = 0,
  active = false,
  selectPrice,
}) => {
  const classes = useStyles({ mr, active });

  const handleSelect = () => {
    selectPrice(active ? undefined : price);
  };

  return (
    <>
      <div onClick={handleSelect} className={classes.cardContainer}>
        <h2
          style={{
            fontSize: "16px",
            margin: "0",
            padding: "0",
            opacity: "80%",
          }}
        >
          STARFARM
        </h2>
        {/* {banner && <div className={classes.banner}>{banner}</div>} */}
        <div>
          <h3
            style={{
              color: "#FFA538",
              fontSize: "24px",
              position: "relative",
              top: "13px",
              textAlign:'center'
            }}
          >
            {stars} Stars
          </h3>
          <img src={starfarmLogo} className={classes.image} />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button className={classes.modalButton}>$ {price}</Button>
        </div>
      </div>
    </>
  );
};

export default PaymentAmountSelect;
