import gql from "graphql-tag";

const SEARCH_BY_TERM_LIBRARY = gql`
  query ($term: String) {
    search(searchTerm: $term, isPlayListSearch: true) {
      playlist {
        _id
        name
        image
      }
    }
  }
`;

export interface SEARCH_BY_TERM_LIBRARYResponse {
    search: {
    playlist:Array<NewPlaylist>
  };
}

export interface SEARCH_BY_TERM_LIBRARYVariables {
  term: string;
}

export default SEARCH_BY_TERM_LIBRARY;
