import NewSongItem from "components/Songs/NewSongItem";
import { observer } from "mobx-react-lite";
import { PlaylistType } from "models/Player";
import React, { FC, useContext } from "react";
import usePlayer from "../context/PlayerContext";
import TokenManager from "core/auth/TokenManager";
interface ISongList {
  songs?: NewSong[];
  playlistId?: string;
  playlistType: PlaylistType;
}

const SongList: FC<ISongList> = ({ songs, playlistId, playlistType }) => {
  const songIds = songs?.map((song) => song._id);
  const player = usePlayer();
  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const selectSong = async (songId: string) => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      player.selectSong(songId, songIds, playlistId, playlistType);
    }
  };

  return (
    <>
      {songs
        ?.filter((song) => song.disabled !== true)
        .map((song, index) => {
          return (
            <NewSongItem
              key={song._id}
              songName={song.name}
              artistName={song.primaryArtist?.displayName}
              songId={song._id}
              albumId={song.album?._id}
              artistId={song.primaryArtist?._id}
              onPlaySong={selectSong}
              index={index}
              costPerPlay={song.costPerPlay}
              playlistId={playlistId}
              playlistType={playlistType}
              artistImage={song.primaryArtist?.image}
            />
          );
        })}
    </>
  );
};

export default observer(SongList);
