import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { FC } from "react";
import musicicon from "picture/musicnotes.png";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() =>
  createStyles({
    MusicIcon: {
      backgroundImage: `url(${musicicon})`,
      width: "160px",
      height: "160px",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      backgroundRepeat: "no-repeat",
      opacity: "0.75",
    },
    body: {
      height: "100%",
      background:
        "linear-gradient(167.96deg, #10354D 22.92%, #02172A 48.44%, #202256 89.58%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      marginTop: "30px",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      width: "205px",
      height: "40px",
      borderRadius: "25px",
    },
    buttonactive: {
      marginTop: "30px",
      width: "205px",
      height: "40px",
      borderRadius: "25px",
      background:
        "linear-gradient(270deg, rgba(93, 196, 178, 0.75) 0%, rgba(32, 34, 86, 0.75) 88.54%)",
    },
    nextbutton: {
      // marginTop: "50px",
      backgroundColor: "#FD4060",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
    nextbuttonDisabled: {
      backgroundColor: "rgba(189, 189, 189, 0.08)",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "70px",
    },
    text: {
      fontSize: "14px",
      color: "rgba(255, 255, 255, 0.5)",
    },
    close: {
      position: "absolute",
      top: "5px",
      left: "5px",
    },
  })
);

interface RegistrationOneProps {
  choice: string;
  handleArtistSelect: any;
  handleStreamerSelect: any;
  handleNext: any;
}

const RegistrationOne: FC<RegistrationOneProps> = ({
  choice,
  handleArtistSelect,
  handleStreamerSelect,
  handleNext,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.body}>
      <Link to="/login" className={classes.close}>
        <IconButton>
          <CloseIcon
            style={{ fontSize: "30px", fill: "rgba(255, 255, 255, 0.5)" }}
          />
        </IconButton>
      </Link>
      <Box className={classes.container}>
        <Box className={classes.MusicIcon}></Box>
        <Typography variant="h6" style={{ marginTop: "15px" }}>
          How Will You Be A Star?
        </Typography>

        {choice === "streamer" ? (
          <Button
            type="button"
            className={classes.buttonactive}
            onClick={handleStreamerSelect}
          >
            <Typography className={classes.text}>Streamer</Typography>
          </Button>
        ) : (
          <Button
            type="button"
            className={classes.button}
            onClick={handleStreamerSelect}
          >
            <Typography className={classes.text}>Streamer</Typography>
          </Button>
        )}

        {choice === "artist" ? (
          <Button
            type="button"
            className={classes.buttonactive}
            onClick={handleArtistSelect}
          >
            <Typography className={classes.text}>Artist</Typography>
          </Button>
        ) : (
          <Button
            type="button"
            className={classes.button}
            onClick={handleArtistSelect}
          >
            <Typography className={classes.text}>Artist</Typography>
          </Button>
        )}
      </Box>

      {
        <Button
          disabled={!choice}
          onClick={handleNext}
          className={!choice ? classes.nextbuttonDisabled : classes.nextbutton}
        >
          <Typography className={classes.text}>Next</Typography>
        </Button>
      }
    </Box>
  );
};

export default RegistrationOne;
