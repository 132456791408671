import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { ChangeEvent, FC, FormEvent, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useUnauthenticatedClient from "jobs/UnathenicatedClient";
import EMAIL_VERIFICATION, {
  EMAIL_VERIFICATIONResponse,
} from "core/graphql/new/mutations/emailVerification";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
// import { TokenManager } from "core/auth/TokenManager";
import TokenManager from "../../core/auth/TokenManager";

const useStyles = makeStyles({
  textfield: {
    width: "100%",
    maxWidth: "500px",
    height: "40px",
    background: "#dbdbdb26",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    "& .MuiInput-root": {
      width: "100%",
      height: "100%",
      borderRadius: "100px",
    },
    "& .MuiInput-underline": {
      "&:before": {
        width: "0%",
      },
      "&:after": {
        width: "90%",
        left: "5%",
      },
    },
  },
  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    backgroundColor: "rgb(255 255 255 / 21%)",
    padding: "50px",
    borderRadius: "10px",
    width: "700px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "#02172a",
  },
  title: {
    color: "white",
    fontFamily: "simplifica",
    fontSize: "64px",
    textAlign: "center",
  },
  subtitle: {
    color: "rgba(84, 186, 180, 0.5)",
    marginBottom: "40px",
    textAlign: "center",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: "35px",
    width: "205px",
    borderRadius: "25px",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#fff",
    marginTop: "20px",
    fontSize: "14px",
    "&:hover": {
      color: "rgba(84, 186, 180, 0.5)",
    },
  },
  fullWidth: {
    width: "100%",
  },
  "@media (max-width: 420px)": {
    contentContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
});

const inputProps = {
  min: 0,
  style: {
    textAlign: "center",
    padding: "0px",
    height: "100%",
    borderRadius: "100px",
  },
};

export const VerifyEmail = () => {
  const history = useHistory();
  const classes = useStyles();
  const client = useUnauthenticatedClient();
  const manager: any = useContext(TokenManager);
  const [email, setEmail] = useState("");
  const [Otp, setOtp] = useState("");
  const [open, setOpen] = useState({ visible: false, data: "", msg: "" });
  const [loading, setLoading] = useState(false);

  const [failed, setFailed] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setEmail(" ");
    manager.clearToken();
    localStorage.removeItem("artist");
    localStorage.removeItem("admin");
    try {
      const { data } = await client.mutate<EMAIL_VERIFICATIONResponse>({
        mutation: EMAIL_VERIFICATION,
        variables: {
          email: email,
          otp: Otp,
        },
      });

      if (data?.verifyUserEmail) {
        setOpen({
          visible: true,
          data: data?.verifyUserEmail,
          msg: "email verified sucessfully",
        });
        manager.clearToken();
        localStorage.removeItem("artist");
        localStorage.removeItem("admin");
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
      //   if (data?.login) {

      //     setToken(data?.login);
      //   }
      return;
    } catch (err) {
      const typedError: any = err as Error;
      setErrorMessage(typedError?.graphQLErrors?.[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen({ visible: false, data: "", msg: "" });
  };
  console.log("open", open);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.contentContainer}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.fullWidth}>
              <Typography variant="h2" className={classes.title}>
                Verify Email
              </Typography>

              <Typography variant="h4" className={classes.subtitle}>
                Please enter your registered email and OTP.
              </Typography>
            </Box>

            {loading === true && <CircularProgress />}
            {failed === true && (
              <Typography color="error" variant="body1">
                Retry log in.
              </Typography>
            )}
          </Box>

          <form onSubmit={handleSubmitForm}>
            <Box className={classes.formContainer}>
              <TextField
                value={email}
                onChange={handleEmailChange}
                placeholder="email@email.com"
                type="email"
                inputProps={inputProps as any}
                className={classes.textfield}
              />

              <TextField
                value={Otp}
                onChange={handleOtpChange}
                placeholder="OTP"
                type="number"
                inputProps={inputProps as any}
                className={classes.textfield}
              />

              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              {open.visible && (
                <Snackbar
                  open={open.visible}
                  autoHideDuration={4000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    {open.msg}
                  </Alert>
                </Snackbar>
              )}
              <Button
                type="submit"
                disabled={loading}
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
