import React, { FC, useMemo } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import { useQuery, useMutation } from "@apollo/react-hooks";
import setAutomaticReload, {
  setAutomaticReloadResponse,
  setAutomaticReloadVariables,
} from "core/graphql/new/mutations/setAutomaticReload";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { CircularProgress } from "@material-ui/core";

interface IThemeProps {
  checked: boolean;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) => ({
  root: {
    "&$checked": {
      color: "#FD4060",
    },
  },
  checked: {},
  spinner: {
    marginRight: "25px",
    height: "15px",
    width: "40px",
  },
}));

interface ICheckBoxWithInfoProps {
  title: string;
  info?: string;
}

const CheckBoxWithInfo: FC<ICheckBoxWithInfoProps> = ({ title, info }) => {
  const {
    data,
    loading: currentUserLoading,
  } = useQuery<GET_CURRENT_USERResponse>(GET_CURRENT_USER);

  const [setAutomaticReloadMutation, { loading }] = useMutation<
    setAutomaticReloadResponse,
    setAutomaticReloadVariables
  >(setAutomaticReload);

  const handleToggle = async () => {
    await setAutomaticReloadMutation({
      variables: {
        reload: !checked,
      },
      refetchQueries: [{ query: GET_CURRENT_USER }],
    });
  };

  const checked = useMemo(() => {
    if (!data) {
      return false;
    }

    return data.currentUser?.automaticReload!;
  }, [data]);

  const classes = useStyles({ checked });

  return (
    <Box display="flex" alignItems="center" paddingTop="15px">
      {loading || currentUserLoading ? (
        <CircularProgress className={classes.spinner} />
      ) : (
        <Checkbox
          classes={{
            root: classes.root,
            checked: classes.checked,
          }}
          checked={checked}
          onClick={handleToggle}
        />
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          style={{ width: info ? "35%" : "100%" }}
          variant="subtitle1"
        >
          {title}
        </Typography>

        {info && (
          <Typography style={{ width: "60%" }} variant="body2">
            {info}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CheckBoxWithInfo;
