import gql from "graphql-tag";

const GET_ARTIST_TIPS = gql`
  query getArtistTips($artistId: String!, $limit: Int) {
    tips: getArtistTips(artistId: $artistId, limit: $limit) {
      streamer {
        firstName
        lastName
        email
      }
      amount
      createdAt
      _id
    }
  }
`;

export interface GET_ARTIST_TIPSResponse {
  tips: Tip[];
}

export interface GET_ARTIST_TIPSVariables {
  artistId: string;
  limit: number;
}

export default GET_ARTIST_TIPS;
