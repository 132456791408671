import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useQuery } from "@apollo/react-hooks";

import GET_USERS_LIKED_SONGS, {
	GET_USERS_LIKED_SONGSResponse,
} from "core/graphql/new/queries/getUsersLikedSongs";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SongList from "containers/SongList";

import PlayButton from "components/Album/PlayButton";
import { PlaylistType } from "models/Player";

interface ILikedSongsProps {
	songListUrl?: string;
}

const useStyles = () =>
	makeStyles((theme) =>
		createStyles({
			block1: {
				padding: `20vh ${theme.spacing(8)}px 0px`,
				[theme.breakpoints.down("md")]: {
					padding: theme.spacing(2),
				},
			},
			block2: {
				padding: `${theme.spacing(8)}px`,
				[theme.breakpoints.down("md")]: {
					padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
				},
			},
			playButton: { borderRadius: 80, width: 100 },
		})
	);

// const loadingState = [1, 2, 3, 4, 5, 6, 7, 8];

const LikedSongsMobile: FC<ILikedSongsProps> = () => {
	const classes = useStyles()();

	const { data } = useQuery<GET_USERS_LIKED_SONGSResponse>(
		GET_USERS_LIKED_SONGS
	);

	return (
		<PageWithBackgroundImage>
			<Box className={classes.block1}>
				<Typography gutterBottom={true} variant="h1" color="primary">
					Liked Songs
				</Typography>

				<Typography gutterBottom={true}>
					{data?.songs.filter((song) => song.disabled !== true).length || 0}{" "}
					Songs
				</Typography>

				<PlayButton
					songs={data?.songs.filter((song) => song.disabled !== true)}
				/>
			</Box>

			<Box className={classes.block2}>
				<Typography gutterBottom={true} variant="h4">
					Album Tracks
				</Typography>

				<SongList
					songs={data?.songs.filter((song) => song.disabled !== true)}
					playlistType={PlaylistType.LIKED_SONGS}
				/>
			</Box>

			<Box height={200} pb={10} />
		</PageWithBackgroundImage>
	);
};

export default LikedSongsMobile;
