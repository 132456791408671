import { useMutation } from "@apollo/react-hooks/lib/useMutation";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ImageWithTitles from "components/@ui/ImageWithTitles";
import ADD_SONG_TO_PLAYLIST, {
  ADD_SONG_TO_PLAYLISTVariables,
} from "core/graphql/new/mutations/AddSongToPlaylist";
import FIND_PLAYLIST_BY_ID from "core/graphql/new/queries/FindPlaylistById";
import React, { FC } from "react";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      box: {
        paddingBottom: "75px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "15px",
        },
      },
    })
  );

interface PlaylistSelectItemsProps {
  songId?: string;
  playlistId?: string;
  image?: string;
  title?: string;
  handleClose: () => void;
}

const PlaylistSelectItems: FC<PlaylistSelectItemsProps> = ({
  songId,
  playlistId,
  image,
  title,
  handleClose,
}) => {
  const [addSongToPlaylist] = useMutation<ADD_SONG_TO_PLAYLISTVariables>(
    ADD_SONG_TO_PLAYLIST,
    {
      refetchQueries: [
        { query: FIND_PLAYLIST_BY_ID, variables: { id: playlistId } },
      ],
    }
  );
  const classes = useStyles()();

  const handleSelect = async () => {
    try {
      await addSongToPlaylist({
        variables: {
          songId: songId,
          playlistId: playlistId,
        },
      });
      handleClose();
      alert("Added");
    } catch (e) {
      console.log(e.message);
      alert("Had trouble add song to playlist.");
    }
  };

  return (
    <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
      <Box className={classes.box} onClick={handleSelect}>
        <ImageWithTitles image={image} title={title} horizontal />
      </Box>
    </Grid>
  );
};

export default PlaylistSelectItems;
