import React, { FC, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import MoreButton from "../components/playlistcomponents/MoreButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box/Box";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import DELETE_PLAYLIST, {
  DELETE_PLAYLISTVariables,
} from "core/graphql/new/mutations/DeletePlaylist";
import GET_USER_PLAYLISTS from "core/graphql/new/queries/getUserPlaylists";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "rgba(255, 255, 255, 0.75)",
    background: "rgba(16, 53, 77, 0.54",
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "rgba(255, 255, 255, 0.75)",
      },
    },
    "&::hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#e0e0e0",
      color: "black",
    },
    dialogActions: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
  })
);

interface DeletePlaylistButtonProps {
  playlistId?: string;
}

const DeletePlaylistButton: FC<DeletePlaylistButtonProps> = ({
  playlistId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [deletePlaylist] = useMutation<DELETE_PLAYLISTVariables>(
    DELETE_PLAYLIST,
    {
      refetchQueries: [{ query: GET_USER_PLAYLISTS }],
    }
  );

  const handleConfirmDelete = async () => {
    try {
      await deletePlaylist({
        variables: {
          playlistId: playlistId,
        },
      });
      alert("Delete");
      history.push("/library/playlist");
    } catch (err) {
      alert("Had trouble deleting playlist");
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MoreButton
        dropDownIcon={
          <SettingsIcon
            style={{ fill: "rgba(255,255,255,0.5", fontSize: "25px" }}
          />
        }
      >
        <StyledMenuItem onClick={handleOpenDialog}>
          <ListItemText primary="Delete Playlist" />
        </StyledMenuItem>
      </MoreButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.dialogContainer}>
          <DialogTitle id="alert-dialog-title">{"Log Out"}</DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{ color: "black" }}
              id="alert-dialog-description"
            >
              Are you sure you want to delete this playlist?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogContainer}>
            <Button onClick={handleClose} style={{ color: "blue" }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} style={{ color: "blue" }}>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default DeletePlaylistButton;
