import { Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TransactionItem from "components/@ui/TransactionItem";
import DualLazy from "./lazyloader";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Box from "components/@ui/Box";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    backgroundColor: "#FD4060",
    width: "100%",
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    width: "100%",
    marginTop: "9vh",
    [theme.breakpoints.down("md")]: {
      marginTop: "5vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "90%",
      maxHeight: "90%",
    },
  },
  list: {
    [theme.breakpoints.up("lg")]: {
      maxHeight: "90%",
      padding: "0px 20px 0px 10px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.2)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-corner": {
        display: "none",
      },
    },
  },
  spinner: {
    color: "white",
  },
  emptyResponse: {
    width: "60%",
  },
  tabs: {
    display: "flex",
    marginLeft: "15px",
    gap: "2rem",
  },
  underline: {
    borderBottom: "2px solid #0ED3BF",
    paddingBottom: "1px",
  },
}));

interface IUserTransactionsProps {
  transactions?: Transaction[];
  loading?: boolean;
  tab?: number;
}

const UserTransactions: FC<IUserTransactionsProps> = ({
  transactions,
  tab,
  loading = false,
}) => {
  const classes = useStyles();
  const [tabs, setTabs] = useState(tab);

  const history = useHistory();

  console.log(transactions, "aagya");

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography style={{ color: "white" }} variant="h4">
          Transactions
        </Typography>

        {loading && (
          <CircularProgress
            size={20}
            className={classes.spinner}
            color="primary"
          />
        )}
      </div>
      <div className={classes.tabs}>
        <h2
          onClick={() => {
            setTabs(1);
            history.push({
              search: "?tabs=1",
            });
          }}
          style={{ cursor: "pointer" }}
          className={tabs === 1 ? classes.underline : ""}
        >
          Streaming
        </h2>
        <h2
          onClick={() => {
            setTabs(2);
            history.push({
              search: "?tabs=2",
            });
          }}
          style={{ cursor: "pointer" }}
          className={tabs === 2 ? classes.underline : ""}
        >
          Tip
        </h2>
      </div>
      {tabs === 1 ? (
        <div className={classes.list}>
          {loading ? (
            [...Array(5)].map((e, index) => {
              console.log("e", e);
              return <TransactionItem loading={loading} key={index} />;
            })
          ) : (
            <>
              {transactions && transactions.length > 0 ? (
                transactions.map((t:any) => {
                  console.log("t", t);
                  if (t.chargeType === "COST_OF_PLAY") {
                    return (
                      <DualLazy
                        height={59}
                        throttle={250}
                        debounce={false}
                        once
                        offset={0}
                      >
                        <TransactionItem
                          tab={1}
                          costOfCharge={t.total}
                          description={t.description}
                          date={t.createdAt}
                          formOfPayment={t.chargeType}
                        />
                      </DualLazy>
                    );
                  }
                })
              ) : (
                <Box width="100%" display="flex" justifyContent="center" mt={4}>
                  <Typography className={classes.emptyResponse} align="center">
                    You haven't made any transactions yet.
                    <br />
                    Make your first transaction today by adding funds into your
                    account to start listening to music.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={classes.list}>
          {loading ? (
            [...Array(5)].map((e, index) => (
              <TransactionItem loading={loading} key={index} />
            ))
          ) : (
            <>
              {transactions && transactions.length > 0 ? (
                transactions.map((t) => (
                  <DualLazy
                    height={59}
                    throttle={250}
                    debounce={false}
                    once
                    offset={0}
                  >
                    <TransactionItem
                      // costOfCharge={t.total}
                      tab={2}
                      amount={t.amount}
                      description={t.recipient.displayName}
                      date={t.createdAt}
                      formOfPayment={t.chargeType}
                    />
                  </DualLazy>
                ))
              ) : (
                <Box width="100%" display="flex" justifyContent="center" mt={4}>
                  <Typography className={classes.emptyResponse} align="center">
                    You haven't made any transactions yet.
                    <br />
                    Make your first transaction today by adding funds into your
                    account to start listening to music.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserTransactions;
