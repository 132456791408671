import gql from "graphql-tag";

const GET_USER_PLAYLISTS = gql`
  query {
    playlists: getUsersPlaylists {
      _id
      name
      image
      user {
        _id
        email
      }
    }
  }
`;

export interface GET_USER_PLAYLISTSResponse {
  playlists: NewPlaylist[];
}

export default GET_USER_PLAYLISTS;
