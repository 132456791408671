import React, { FC, useMemo } from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import usePlayer from "context/PlayerContext";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      topLabel: {
        textAlign: "center",
        color: "white",
        marginBottom: "20px",
        fontWeight: "bold",
      },
      button: {
        marginTop: "30px",
        backgroundColor: "#b74260",
        color: "white",
        minWidth: "125px",
        borderRadius: "25px",
        marginBottom: "20px",
        border: "none",
        "&:hover": {
          cursor: "pointer",
        },
      },
      iconButton: { position: "absolute", top: "5px", right: "10px" },
      text: { textAlign: "center" },
      textBox: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      dialogActions: {
        color: "rgba(255,255,255,0.7)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
      closeIcon: {
        fill: "#ffffff59",
        fontSize: "25px",
      },
    })
  );

interface IInsufficientFundsAlertProps {
  open: any;
  handleClose: any;
}

const InsufficientFundsAlert: FC<IInsufficientFundsAlertProps> = ({
  open,
  handleClose,
}) => {
  const classes = useStyles()();

  const history = useHistory();

  const player = usePlayer();

  const handleCloseDialog = () => {
    handleClose(false);
    player.relieveError();
  };

  const error = useMemo(() => {
    if (!player.error) {
      return;
    }

    console.log(player.error);

    const error =
      player.error === "Not enough funds"
        ? errorMessages[0]
        : player.error === "Unable to find song."
        ? errorMessages[1]
        : player.error === "User not found."
        ? errorMessages[2]
        : errorMessages[3];

    return error;
  }, [player.error]);

  if (!player.error || !error) {
    handleCloseDialog();
  }

  const handleRedirect = () => {
    if (error && error?.link) {
      handleClose(false);
      player.relieveError();
      history.push(error.link);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "rgb(54 68 76 / 0.9)",
          minWidth: "250px",
          minHeight: "175px",
          maxWidth: "335px",
        },
      }}
    >
      <DialogTitle id="form-dialog-title" className={classes.topLabel}>
        {error?.title}
      </DialogTitle>

      <DialogContent>
        <IconButton
          className={classes.iconButton}
          onClick={() => {
            handleClose(false);
            handleCloseDialog();
          }}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <Box className={classes.textBox}>
          <Typography className={classes.text}>{error?.body}</Typography>
        </Box>
      </DialogContent>

      {error && error?.button && (
        <DialogActions>
          <Box className={classes.dialogActions}>
            <Button className={classes.button} onClick={handleRedirect}>
              {error.button}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

interface ErrorMessage {
  title: string;
  body: string;
  button?: string;
  link?: string;
}

const errorMessages: ErrorMessage[] = [
  {
    title: "Insufficient Funds",
    body:
      "You currently do not have enough funds to play this song. Please add funds into your account from your account page.",
    button: "Add Funds To Your Account",
    link: "/account",
  },
  {
    title: "Unable to find song",
    body: "Sorry, we couldn't find the song you had requested",
  },
  {
    title: "Please Sign In",
    body: "Sign in, or create an account, to start listening to music.",
    button: "Sign In",
    link: "/login",
  },
  {
    title: "Service Unavailable",
    body:
      "Service appears to be currently unavailable, give us a moment while we figure it out.",
  },
];

export default InsufficientFundsAlert;
