import gql from "graphql-tag";

const GET_USER_TRANSACTIONS = gql`
  query {
    transactions: transactionsByCurrentUser {
      _id
      total
      description
      chargeType
      createdAt
    }
  }
`;

export interface GET_USER_TRANSACTIONSResponse {
  transactions: Transaction[];
}

export default GET_USER_TRANSACTIONS;
