import gql from "graphql-tag";

const EMAIL_VERIFICATION = gql`
  mutation ($email: String!, $otp: String!) {
    verifyUserEmail(email: $email, otp: $otp)
  }
`;

export interface EMAIL_VERIFICATIONResponse {
  verifyUserEmail: any;
}

export interface EMAIL_VERIFICATIONVariables {
  email: string;
  otp: number;
}

export default EMAIL_VERIFICATION;
