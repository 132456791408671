import React, { FC } from "react";
import SkipNextIcon from "@material-ui/icons/SkipNext";

interface ISkipNextButtonProps {
  small?: boolean;
}

const SkipNextButton: FC<ISkipNextButtonProps> = ({ small = false }) => {
  return (
    <SkipNextIcon
      style={{ fontSize: small ? "25px" : "4vh", fill: "#3beafb" }}
    />
  );
};

export default SkipNextButton;
