import { FlagReasons } from "FlagReasons";
import gql from "graphql-tag";


const FLAG_SONG = gql`
  mutation($songId: String, $reason: String, $additionalComment: String) {
    flagSong(songId: $songId, reason: $reason, additionalComment: $additionalComment){
        _id
    }
  }
`;

export interface FLAG_SONGVariables {
 songId: string;
 reason: FlagReasons;
 additonalComment?: string;
}

export interface FLAG_SONGResponse {
    song: Song
}


export default FLAG_SONG;