import React, { FC } from "react";

import Typography from "@material-ui/core/Typography";

import { useMutation } from "@apollo/react-hooks/lib/useMutation";

import REMOVE_SONG_FROM_PLAYLIST, {
  REMOVE_SONG_FROM_PLAYLISTVariables,
} from "core/graphql/new/mutations/RemoveSongFromPlaylist";
import FIND_PLAYLIST_BY_ID from "core/graphql/new/queries/FindPlaylistById";

interface RemoveSongFromPlaylistProps {
  songId?: string;
  playlistId?: string;
}

const RemoveSongFromPlaylistButton: FC<RemoveSongFromPlaylistProps> = ({
  songId,
  playlistId,
}) => {
  const [
    removeSongFromPlaylist,
  ] = useMutation<REMOVE_SONG_FROM_PLAYLISTVariables>(
    REMOVE_SONG_FROM_PLAYLIST,
    {
      refetchQueries: [
        { query: FIND_PLAYLIST_BY_ID, variables: { id: playlistId } },
      ],
    }
  );

  const handleClick = async () => {
    try {
      await removeSongFromPlaylist({
        variables: {
          songId: songId,
          playlistId: playlistId,
        },
      });

      alert("Removed");
    } catch (err) {
      alert("Had trouble removing song from playlist");
    }
  };

  return (
    <Typography onClick={handleClick} variant="body1">
      Remove from Playlist
    </Typography>
  );
};

export default RemoveSongFromPlaylistButton;
