export const fonts = [
  "creepster",
  "bangers",
  "ceviche one",
  "unica one",
  "paytone one",
  "permanent marker",
  "teko",
  "fascinate",
  "akaya telivigala",
];
