import React, { FC } from "react";

import { useQuery } from "@apollo/react-hooks";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import AlbumContainer from "containers/AlbumContainer";
import HorizontalList from "components/@ui/HorizontalList";
import Hidden from "@material-ui/core/Hidden/Hidden";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import GET_TOP_SONGS_BY_GENRE, {
	GET_TOP_SONGS_BY_GENREResponse,
	GET_TOP_SONGS_BY_GENREVariables,
} from "core/graphql/new/queries/findPopularSongsByGenre";
import GET_TOP_ALBUMS_BY_GENRE, {
	GET_TOP_ALBUMS_BY_GENREResponse,
	GET_TOP_ALBUMS_BY_GENREVariables,
} from "core/graphql/new/queries/findPopularAlbumsByGenre";
import SongList from "containers/SongList";
import { PlaylistType } from "models/Player";
import { DateTime } from "luxon";

interface IResponsiveDashboardProps
	extends RouteComponentProps<{ id: string }> {}

const useStyles = () =>
	makeStyles((theme) =>
		createStyles({
			backButton: {
				width: "100%",
				display: "flex",
				paddingRight: "10px",
				paddingTop: "10px",
			},
			songItem: {
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			},
			albumItem: {
				display: "flex",
				flexDirection: "row",
				paddingBottom: "75px",
				alignItems: "end",
			},
			headings: {
				paddingTop: "15vh",
				paddingLeft: "15px",
				paddingBottom: "5vh",
			},
			textSpacer: { marginRight: "10px" },
			albumHeading: { paddingLeft: "24px" },
			albumContainer: { display: "inline-block" },
		})
	);

const GenrePage: FC<IResponsiveDashboardProps> = ({ match }) => {
	const history = useHistory();
	const classes = useStyles()();

	const { data: topSongs } = useQuery<
		GET_TOP_SONGS_BY_GENREResponse,
		GET_TOP_SONGS_BY_GENREVariables
	>(GET_TOP_SONGS_BY_GENRE, { variables: { genre: match.params.id } });

	const { data: topalbums } = useQuery<
		GET_TOP_ALBUMS_BY_GENREResponse,
		GET_TOP_ALBUMS_BY_GENREVariables
	>(GET_TOP_ALBUMS_BY_GENRE, { variables: { genre: match.params.id } });

	const handleBack = () => {
		history.goBack();
	};

	return (
		<>
			<PageWithBackgroundImage>
				<Hidden mdUp={true}>
					<Box className={classes.backButton}>
						<IconButton onClick={handleBack}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
				</Hidden>

				<Box className={classes.headings}>
					<Typography gutterBottom={true} variant="h1" color="primary">
						{match.params.id}
					</Typography>

					<Typography gutterBottom={true} variant="h3">
						This Week's Top Trending
					</Typography>
				</Box>

				<>
					<Box width="100%" mb={6}>
						<Typography
							className={classes.albumHeading}
							variant="h4"
							color="textSecondary"
							gutterBottom={true}
						>
							Albums
						</Typography>

						<HorizontalList id="PlayLists">
							{topalbums?.albums?.map((album, index) => (
								<Box className={classes.albumContainer}>
									<Box className={classes.albumItem}>
										<Typography variant="h4" className={classes.textSpacer}>
											{`#${index + 1}`}
										</Typography>
										<AlbumContainer
											key={album._id}
											image={album.imageUrl}
											title={album.title}
											artistName={album.primaryArtist?.displayName}
											artistId={album.primaryArtist?._id}
											albumId={album._id}
											isSingle={album.isSingle}
											extraPadding
											tertiaryTitle={
												album.releaseDate
													? DateTime.fromISO(album.releaseDate!).toFormat(
															"yyyy"
													  )
													: "2021"
											}
										/>
									</Box>
								</Box>
							))}
						</HorizontalList>
					</Box>
				</>

				<Box px={3}>
					<Typography color="textSecondary" variant="h4" gutterBottom={true}>
						Songs
					</Typography>
					<SongList
						songs={topSongs?.songs}
						playlistType={PlaylistType.GENRE}
						playlistId={match.params.id}
					/>
				</Box>
				<Box height={250} pb={10} />
			</PageWithBackgroundImage>
		</>
	);
};

export default GenrePage;
