import React, { FC, useContext, useState } from "react";
import DetailedReportModal from "components/DetailedReportModal";
import FLAG_ALBUM, {
  FLAG_ALBUMResponse,
  FLAG_ALBUMVariables,
} from "core/graphql/new/mutations/FlagAlbum";
import { useMutation } from "@apollo/react-hooks";
import FeedbackModal from "./FeedbackModal";
import { observer } from "mobx-react-lite";
import ReportFormContext from "stores/ReportFormStore";

interface ReportAlbumModalProps {
  handleClose: () => void;
}

const ReportAlbumModal: FC<ReportAlbumModalProps> = observer(
  ({ handleClose }) => {
    const store = useContext(ReportFormContext);

    const [openFeedback, setOpenFeedback] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);

    const [submitAlbumReport] = useMutation<
      FLAG_ALBUMResponse,
      FLAG_ALBUMVariables
    >(FLAG_ALBUM);

    const handleSubmit = async () => {
      console.log("click");

      console.log("id: ", store.id, "store reason: ", store.reportedReason);

      if (store.id && store.reportedReason) {
        console.log("making it into if");
        try {
          await submitAlbumReport({
            variables: {
              albumId: store.id,
              reason: store.reportedReason,
              additonalComment: store.otherComment,
            },
          });
          console.log("shouldve completed flag");
        } catch (error) {
          console.log(error);
          setFailed(true);
        }
      }
      setOpenFeedback(true);
    };

    const handleClosingFeedback = () => {
      handleClose();
      setOpenFeedback(false);
      setFailed(false);
    };

    return (
      <>
        {openFeedback ? (
          <FeedbackModal failed={failed} handleClose={handleClosingFeedback} />
        ) : (
          <DetailedReportModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
);

export default ReportAlbumModal;
