import React, { FC, useContext, useEffect } from "react";

import Box from "@material-ui/core/Box/Box";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Typography from "@material-ui/core/Typography/Typography";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Drawer from "@material-ui/core/Drawer/Drawer";
import { Home, Search, Favorite } from "@material-ui/icons";
import starFarmLogo from "picture/starFarm.png";
import { useHistory } from "react-router-dom";
import MusicPlayer from "containers/MusicPlayer";
import NewPlaylistDialog from "./Desktop/components/NewPlaylistDialog";
import ProfileSettingsButton from "./Desktop/components/ProfileSettingsButton";
import DescriptionAlert from "components/@ui/DescriptionAlert";
import EventMessageContext from "stores/EventMessage";
import { observer } from "mobx-react-lite";
import AccountBalance from "containers/AccountBalance";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CombinedReportModal from "containers/CombinedReportModal";
import TokenManager from "core/auth/TokenManager";
import usePlayer from "context/PlayerContext";
import gql from "graphql-tag";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import { useLazyQuery } from "@apollo/react-hooks";

const useStyles = makeStyles<Theme>((theme) => ({
  drawer: {
    width: 300,
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: 300,
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "linear-gradient(174.27deg, #02172A 29.14%, #10354D 100%)",
  },
  musicPlayer: {
    background:
      "linear-gradient(269.62deg, rgb(93, 196, 178) -27.14%, rgb(16, 53, 77) 24.63%, rgb(16, 53, 77) 50.49%, rgb(9, 41, 70) 75%)",
  },
  contentContainer: {
    marginLeft: 300,
    position: "relative",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  bottomBar: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 99,
    width: "100%",
    paddingLeft: 300,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  bottomNav: {
    display: "none",
    background:
      "linear-gradient(269.62deg, rgb(93, 196, 178) -27.14%, rgb(16, 53, 77) 24.63%, rgb(16, 53, 77) 50.49%, rgb(9, 41, 70) 75%)",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  accountBalance: {
    marginLeft: "20px",
    color: "#58c3b1",
    fontSize: "16px",
  },
  starFarmBackground: {
    backgroundImage: `url(${starFarmLogo})`,
    width: "auto",
    height: 220,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: "24px auto -20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  reportModal: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

interface ILayoutProps {}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const classes = useStyles();

  const alertManager = useContext(EventMessageContext);
  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const history = useHistory();
  const player = usePlayer();

  const handleHome = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      history.push("/");
    }
  };

  const handleSearch = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      history.push("/search");
    }
  };

  const handleLibrary = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      history.push("/library/playlist");
    }
  };

  const handleNotification = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      history.push("/notifications");
    }
  };

  const handleAccount = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      history.push("/account?tab=1");
    }
  };
  const [getUser, { data }] =
    useLazyQuery<GET_CURRENT_USERResponse>(GET_CURRENT_USER);
  const handleNotifications = () => {
    try {
      getUser();
    } catch (err) {
      const typedError: any = err as Error;
      console.log(typedError?.message);
    }
  };
  useEffect(() => {
    handleNotifications();
  }, []);
  console.log("getUser", data?.currentUser?.pendingNotification);

  return (
    <Box>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Box>
          <Box onClick={handleHome} className={classes.starFarmBackground} />

          <Typography
            align="center"
            variant="h1"
            style={{
              fontFamily: "simplifica",
              letterSpacing: 6,
              fontSize: 60,
              marginBottom: 64,
            }}
          >
            StarFarm
          </Typography>

          <MenuItem onClick={handleHome}>
            <Typography variant="h3">HOME</Typography>
          </MenuItem>

          <MenuItem onClick={handleSearch}>
            <Typography variant="h3">SEARCH</Typography>
          </MenuItem>

          <MenuItem onClick={handleLibrary}>
            <Typography variant="h3">LIBRARY</Typography>
          </MenuItem>

          <MenuItem className="position-relative" onClick={handleNotification}>
            <Typography variant="h3">NOTIFICATIONS</Typography>
            {data?.currentUser?.pendingNotification ? (
              <span
                style={{
                  height: "10px",
                  borderRadius: "50%",
                  width: "10px",
                  backgroundColor: "red",
                  position: "absolute",
                  right: "40px",
                  top: "5px",
                }}
              ></span>
            ) : (
              <></>
            )}
          </MenuItem>
          <MenuItem onClick={handleAccount}>
            <Typography variant="h3">ACCOUNT</Typography>
          </MenuItem>
        </Box>

        <Box>
          <Box display="flex" alignItems="center" pb={2} pl={1}>
            <ProfileSettingsButton />
            <AccountBalance style={classes.accountBalance} />
          </Box>

          <NewPlaylistDialog />
        </Box>
      </Drawer>

      <Box className={classes.contentContainer}>
        <Box>{children}</Box>

        <Box className={classes.reportModal}>
          <CombinedReportModal />
        </Box>

        <Box className={classes.bottomBar}>
          {alertManager.severityType && <DescriptionAlert />}
          {player.currentSongOrder.length ? (
            <Box className={classes.musicPlayer}>
              <MusicPlayer />
            </Box>
          ) : (
            ""
          )}
          <BottomNavigation
            value={history.location.pathname}
            showLabels
            className={classes.bottomNav}
          >
            <BottomNavigationAction
              value="/"
              onClick={handleHome}
              label="Home"
              icon={<Home />}
            />

            <BottomNavigationAction
              onClick={handleSearch}
              value="/search"
              label="Search"
              icon={<Search />}
            />
            <BottomNavigationAction
              onClick={handleLibrary}
              value="/library"
              label="Library"
              icon={<Favorite />}
            />
            <BottomNavigationAction
              onClick={handleAccount}
              value="/account"
              icon={<AccountBalance />}
            />
          </BottomNavigation>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Layout);
