import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import DollarValue from "components/@ui/DollarValue";
import TIP_ARTIST, {
  TIP_ARTISTResponse,
  TIP_ARTISTVariables,
} from "core/graphql/new/mutations/tipArtist";
import GET_ARTIST_TIPS from "core/graphql/new/queries/getArtistTips";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import WALLET_BALANCE_SUB, {
  WalletBalanceSubResponse,
} from "core/graphql/subscriptions/walletBalance";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import EventMessageContext from "stores/EventMessage";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      actionBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "12px",
      },
      currencyInputBox: {
        color: "rgb(255 255 255 / 0.5)",
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "50%",
      },
      input: {
        color: "rgb(255 255 255 / 0.75)",
        border: "none",
        background: "transparent",
        borderRadius: "25px",
        fontSize: "xx-large",
        maxWidth: "100%",
        textAlign: "center",
        "&::-webkit-input-placeholder": {
          color: "rgb(255 255 255 / 0.5)",
        },
        "&:focus": {
          outline: "none",
        },
      },
      button: {
        background: "#386d6a",
        width: "50%",
        borderRadius: "100px",
        marginTop: "16px",
      },
      container: {
        width: "100%",
        padding: "10px 50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    })
  );

interface ITipArtistActionProps {
  artistId: string;
  artistName?: string;
}

const TipArtistActions: FC<ITipArtistActionProps> = ({
  artistId,
  artistName,
}) => {
  const { data: userData } = useQuery<GET_CURRENT_USERResponse>(
    GET_CURRENT_USER,
    { pollInterval: 1000 }
  );

  useSubscription<WalletBalanceSubResponse>(WALLET_BALANCE_SUB);

  const classes = useStyles()();

  const alertManager = useContext(EventMessageContext);

  const [TipArtistModal] = useMutation<TIP_ARTISTResponse, TIP_ARTISTVariables>(
    TIP_ARTIST,
    {
      refetchQueries: [
        { query: GET_CURRENT_USER },
        { query: GET_ARTIST_TIPS, variables: { artistId, limit: 5 } },
      ],
    }
  );

  const [tipAmount, setTipAmount] = useState<string>("0");

  const handleSendTip = async () => {
    if (
      !tipAmount ||
      parseFloat(tipAmount) <= 0 ||
      (userData?.currentUser?.balanceInPennies &&
        parseFloat(tipAmount) > userData?.currentUser?.balanceInPennies / 100)
    ) {
      return;
    }

    if (tipAmount && artistId) {
      try {
        await TipArtistModal({
          variables: {
            artistId: artistId,
            amount: parseFloat(tipAmount) * 100,
          },
        });

        alertManager.setEvent(
          "success",
          "Success",
          `Sent tip to ${artistName || "artist"}`
        );

        setTipAmount("0");
      } catch (err) {
        const typedError: any = err as Error;
        alertManager.setEvent("error", "Error", typedError.graphQLErrors[0].message);
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.actionBox}>
        <Typography gutterBottom variant="h4" color="secondary">
          <DollarValue
            value={(userData?.currentUser?.balanceInPennies || 0)}
          />
        </Typography>

        <Typography align="center" gutterBottom variant="h3">
          StarFarm Balance
        </Typography>
      </Box>
      <Typography gutterBottom variant="body2" align="center">
        Show your support for this artist by sending them a tip directly from
        your StarFarm Balance:
      </Typography>
      <Box className={classes.currencyInputBox}>
        <CurrencyInput
          value={tipAmount}
          onValueChange={(value) => setTipAmount(value || "0")}
          className={classes.input}
          placeholder="0 Stars"
          suffix=" Stars"
        />
      </Box>
      <Button
        disabled={
          !tipAmount ||
          parseFloat(tipAmount) <= 0 ||
          (userData?.currentUser?.balanceInPennies &&
            parseFloat(tipAmount) >
              userData?.currentUser?.balanceInPennies / 100)
            ? true
            : false
        }
        onClick={handleSendTip}
        className={classes.button}
      >
        Send Tip
      </Button>
    </Box>
  );
};

export default observer(TipArtistActions);
