import React, { FC, useContext, useState } from "react";
import DetailedReportModal from "components/DetailedReportModal";
import { observer } from "mobx-react-lite";
import FLAG_SONG, {
  FLAG_SONGResponse,
  FLAG_SONGVariables,
} from "core/graphql/new/mutations/FlagSong";
import { useMutation } from "@apollo/react-hooks";
import FeedbackModal from "./FeedbackModal";
import ReportFormContext from "stores/ReportFormStore";

interface ReportSongModalProps {
  handleClose: () => void;
}

const ReportSongModal: FC<ReportSongModalProps> = observer(
  ({ handleClose }) => {
    const store = useContext(ReportFormContext);
    const [openFeedback, setOpenFeedback] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);

    const [submitSongReport] = useMutation<
      FLAG_SONGResponse,
      FLAG_SONGVariables
    >(FLAG_SONG);

    const handleSubmit = async () => {
      if (store.id && store.reportedReason) {
        try {
          await submitSongReport({
            variables: {
              songId: store.id,
              reason: store.reportedReason,
              additonalComment: store.otherComment,
            },
          });
        } catch (error) {
          setFailed(true);
        }
      }
      setOpenFeedback(true);
    };

    const handleClosingFeedback = () => {
      handleClose();
      setOpenFeedback(false);
      setFailed(false);
    };

    return (
      <>
        {openFeedback ? (
          <FeedbackModal failed={failed} handleClose={handleClosingFeedback} />
        ) : (
          <DetailedReportModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
);

export default ReportSongModal;
