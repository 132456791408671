import gql from "graphql-tag";

const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email)
  }
`;

export interface FORGOT_PASSWORDResponse {
    forgotPassword: any;
}

export interface FORGOT_PASSWORDVariables {
  email: string;
}

export default FORGOT_PASSWORD;
