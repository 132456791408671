import React, { FC } from "react";
import ImageWithTitles from "components/@ui/ImageWithTitles";

interface IAlbumContainerProps {
  title?: string;
  artistName?: string;
  isSingle?: boolean;
  image?: string;
  albumId?: string;
  artistId?: string;
  extraPadding?: boolean;
  tertiaryTitle?: string;
}

const AlbumContainer: FC<IAlbumContainerProps> = ({
  title,
  artistName,
  isSingle = false,
  extraPadding = false,
  image,
  albumId,
  artistId,
  tertiaryTitle,
}) => {
  const subtitle = isSingle
    ? `${artistName} - Single`
    : `${artistName} - Album`;

  const titleLink = `/album/${albumId}`;

  const subTitleLink = `/artist/${artistId}`;

  return (
    <ImageWithTitles
      titleLink={titleLink}
      subTitleLink={subTitleLink}
      title={title}
      subTitle={subtitle}
      image={image}
      extraPadding={extraPadding}
      tertiaryTitle={tertiaryTitle}
    />
  );
};

export default AlbumContainer;
