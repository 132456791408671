import { FlagReasons } from "FlagReasons";
import { action, computed, observable } from "mobx";
import { createContext } from "react";

export class ReportFormStore {
  @observable
  public open: boolean = false;

  @action
  public openModal = () => {
    this.open = true;
  };

  @action
  public closeModal = () => {
    this.open = false;
  };

  @observable
  public type: string = "";

  @observable
  public setType = (newType: string) => {
    this.type = newType;
  };

  @observable
  public option: string = "Spam";

  @observable
  public id: string = "";

  @action
  public setId = (newId: string) => {
    this.id = newId;
  };

  @action
  public setOption = (newOption: string) => {
    this.option = newOption;
  };

  @observable
  public otherComment?: string;

  @action
  public setOtherComment = (newOtherComment: string) => {
    this.otherComment = newOtherComment;
  };

  @computed
  public get reportedReason() {
    if (this.option === "Spam") {
      return FlagReasons.SPAM;
    }

    if (this.option === "Abusive or Violent Content") {
      return FlagReasons.ABUSIVE_VIOLENT_CONTENT;
    }

    if (this.option === "Sexually Explicit Content") {
      return FlagReasons.SEXUAL_CONTENT;
    }

    if (this.option === "Other") {
      return FlagReasons.OTHER;
    }

    return undefined;
  }
}

const ReportFormContext = createContext(new ReportFormStore());

export default ReportFormContext;
