import gql from "graphql-tag";

const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!, $webPushToken: String) {
    login: login(
      email: $email
      password: $password
      webPushToken: $webPushToken
    ) {
      token
      isArtist
      isAdmin
    }
  }
`;

interface loginInterface {
  token: string;
  isArtist: boolean;
  isAdmin: boolean;
}

export interface LOGIN_USERResponse {
  login: loginInterface;
}

export interface LOGIN_USERVariables {
  email: string;
  password: string;
  webPushToken: string;
}

export default LOGIN_USER;
