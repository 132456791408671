import React, { useContext } from "react";
import usePlayer from "context/PlayerContext";
import { observer } from "mobx-react-lite";
import { makeStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useMutation } from "@apollo/react-hooks";
import setAutoPlay, {
  setAutoPlayResponse,
  setAutoPlayVariables,
} from "core/graphql/new/mutations/setAutoPlay";

import EventMessageContext from "stores/EventMessage";

interface Props {
  small?: boolean;
  checkBox?: boolean;
}
const useStyles = makeStyles<Theme>((theme) => ({
  iconButton: {
    padding: "5px",
  },
}));

const AutoPlayButton: React.FC<Props> = ({ small = false, checkBox }) => {
  const player = usePlayer();
  const classes = useStyles();
  const alertManager = useContext(EventMessageContext);

  const [setAuto] = useMutation<setAutoPlayResponse, setAutoPlayVariables>(
    setAutoPlay
  );

  const handleClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    autoPlay: boolean
  ) => {
    e.stopPropagation();
    player.setAutoPlay(autoPlay);
    alertManager.setEvent(
      "success",
      "",
      `Auto Play has been ${autoPlay ? "enabled" : "disabled"}`
    );
    await setAuto({
      variables: {
        autoPlay: autoPlay,
      },
    });
  };

  return (
    <>
      {player.autoPlay === true ? (
        <IconButton
          className={classes.iconButton}
          onClick={(e) => {
            handleClick(e, false);
          }}
        >
          <AutorenewIcon
            style={{ fontSize: small ? "18px" : "2.75vh", fill: "#FD4060" }}
          />
        </IconButton>
      ) : (
        <IconButton
          className={classes.iconButton}
          onClick={(e) => {
            handleClick(e, true);
          }}
        >
          <AutorenewIcon
            style={{ fontSize: small ? "18px" : "2.75vh", fill: "#ffffff4d" }}
          />
        </IconButton>
      )}
    </>
  );
};

export default observer(AutoPlayButton);
