import React, { FC } from "react";

import Menu from "@material-ui/core/Menu";

import { IconButton, makeStyles } from "@material-ui/core";

interface IMoreButtonProps {
  dropDownIcon: any;
  setClose?: any;
  setOpen?: any;
  anchor?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    background: "#02172A",
  },
}));

const MoreButton: FC<IMoreButtonProps> = ({
  children,
  dropDownIcon,
  setOpen,
  setClose,
  anchor,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e: any) => {
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e: any) => {
    e?.stopPropagation();

    if (setClose) {
      setClose(null);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={setOpen ? setOpen : handleClick}
        style={{ padding: "0px" }}
      >
        {dropDownIcon}
      </IconButton>

      <Menu
        id="customized-menu"
        anchorEl={anchor ? anchor : anchorEl}
        open={anchor ? Boolean(anchor) : Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ list: classes.paper }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default MoreButton;
