import React, { ChangeEvent, useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button/Button";
import CREATE_NEW_PLAYLIST, {
  CREATE_NEW_PLAYLISTVariables,
} from "core/graphql/new/mutations/CreateNewPlaylist";
import { useMutation } from "@apollo/react-hooks/lib/useMutation";
import GET_USER_PLAYLISTS from "core/graphql/new/queries/getUserPlaylists";
import TokenManager from "core/auth/TokenManager";
import axios from "axios";
import musicicon from "picture/upload.svg";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      input: {
        height: "25px",
        borderRadius: "25px",
        background: "rgb(255 255 255 / 0.55)",
        border: "none",
        paddingLeft: "10px",
        color: "black",
        fontWeight: "bold",
        "&::-webkit-input-placeholder": {
          color: "rgb(0 0 0 / 0.5)",
        },
        "&:focus": {
          outline: "none",
          background: "rgb(255 255 255 / 0.65)",
        },
      },
      label: {
        color: "rgba(255, 255, 255, 0.7)",
        fontWeight: "bold",
        marginBottom: "20px",
      },
      topLabel: {
        textAlign: "center",
        color: "rgba(255,255,255,.65)",
        fontWeight: "bold",
      },
      button: {
        marginTop: "30px",
        backgroundColor: "rgba(102, 199, 179, 0.75)",
        color: "rgba(255,255,255,0.7)",
        width: "150px",
        height: "40px",
        borderRadius: "25px",
        marginBottom: "20px",
        border: "none",
        "&:hover": {
          cursor: "pointer",
        },
      },
      buttonContainer: {
        display: "flex",
        paddingLeft: "20px",
        alignItems: "center",
        background: "rgb(255 255 255 / .15)",
        width: "100%",
        justifyContent: "space-between",
      },

      contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      formContainer: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "30px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: "0px",
          paddingTop: "20px",
          width: "95%",
        },
      },
    })
  );

const NewPlaylistDialog = () => {
  const [createNewPlaylist] = useMutation<CREATE_NEW_PLAYLISTVariables>(
    CREATE_NEW_PLAYLIST,
    {
      refetchQueries: [{ query: GET_USER_PLAYLISTS }],
    }
  );

  const classes = useStyles()();
  const [open, setOpen] = useState(false);

  const [playlistName, setPlaylistName] = useState("");
  const [playlistImage, setPlaylistImage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [imageSize, setImageSize] = useState<any>("");
  const [validatePlaylist, setValidatePlayList] = useState<boolean>(false);
  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const handleClickOpen = () => {
    if (token === "undefined") {
      window.location.href = "/login";
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setPlaylistName("");
    setPlaylistImage("");
    setPreviewImage("");
    setOpen(false);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    // if (playlistName.length < 1) {
    //   setValidatePlayList(true);
    //   return;
    // } else {
    //   setValidatePlayList(false);
    // }
    console.log("Called");

    await createNewPlaylist({
      variables: {
        name: playlistName,
        image: playlistImage,
      },
    });
    setOpen(false);
    setPlaylistName("");
    setPlaylistImage("");
  };
  console.log(playlistName);

  // const handleNameChange = (e: any) => {
  //   setPlaylistName(e.target.value);
  // };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    formData.append("file", e.target.files ? e.target.files?.[0] : "");
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    let imgSize = e.target.files[0]?.size;
    setImageSize(imgSize);
    axios
      .post(`https://dev-apis.starfarm.com/song/upload-image`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setPreviewImage(res.data.url);
        setPlaylistImage(res.data.url);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Box className={classes.buttonContainer}>
        <Typography variant="h4">New Playlist</Typography>

        <IconButton onClick={handleClickOpen}>
          <AddIcon style={{ fill: "rgb(255 255 255 / .5)" }} />
        </IconButton>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "rgb(54 68 76 / 0.9)",
            maxWidth: "700px",
            width: "80%",
          },
        }}
      >
        <DialogTitle className={classes.topLabel}>
          CREATE NEW PLAYLIST
        </DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent>
            <Box className={classes.contentContainer}>
              <IconButton
                style={{ position: "absolute", top: "5px", right: "10px" }}
                onClick={handleClose}
              >
                <CloseIcon style={{ fill: "#ffffff59", fontSize: "25px" }} />
              </IconButton>

              <Box className={classes.formContainer}>
                <label htmlFor="name" className={classes.label}>
                  IMAGE UPLOAD
                </label>
                <label htmlFor="image" style={{ cursor: "pointer" }}>
                  <div>
                    <img
                      style={{ width: "200px" }}
                      src={previewImage ? previewImage : musicicon}
                    />
                    {imageSize > 1000000 ? (
                      <Typography
                        style={{ color: "red", fontSize: "12px" }}
                        variant="h6"
                      >
                        Please upload an image of size less than 1 MB.
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </div>
                  <br></br>
                  {/* <div>
                  <img style={{width:'200px'}} src={previewImage} />
                </div> */}
                </label>
                <input
                  type="file"
                  id="image"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="name" className={classes.label}>
                  NAME
                </label>
                <input
                  type="text"
                  className={classes.input}
                  // id="name-test"
                  // autoFocus
                  placeholder="Playlist #1"
                  value={playlistName}
                  onChange={(e: any) => {
                    // e.target.focus()
                    console.log("e", e.target);
                    setPlaylistName(e.target.value);
                  }}
                />
                {/* <input
                  type="text"
                  value={playlistName}
                  onChange={(e: any) => setPlaylistName(e.target.value)}
                /> */}
                {validatePlaylist && playlistName.length < 1 ? (
                  <Typography
                    variant="body2"
                    style={{ fontSize: "13px", color: "red", marginTop: "5px" }}
                  >
                    Please enter the playlist name
                  </Typography>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <Box
              color="rgba(255,255,255,0.7)"
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Button className={classes.button} type="submit">
                Create
              </Button>
            </Box>
          </DialogContent>
        </form>
        {/* <DialogActions>
            
          </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default NewPlaylistDialog;
