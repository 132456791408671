import { useMemo } from "react";
import { InMemoryCache } from "apollo-cache-inmemory/lib/inMemoryCache";
import ApolloClient from "apollo-client/ApolloClient";
import { HttpLink } from "apollo-link-http/lib/httpLink";
import getEnvironmentVariable from "../core/utils/getEnvironmentVariable";

const baseUrl = getEnvironmentVariable("REACT_APP_API_BASE_URL");

const useUnauthenticatedClient = () => {
  const apiclient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${baseUrl}/graphql`,
    });

    const client = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache({
        addTypename:false
      }),
    });

    return client;
  }, []);

  return apiclient;
};

export default useUnauthenticatedClient;
