import React, { FC } from "react";
import ImageWithTitles from "components/@ui/ImageWithTitles";

interface IArtistContainerProps {
  title?: string;
  image?: string;
  artistId?: string;
}

const ArtistContainer: FC<IArtistContainerProps> = ({
  title,
  image,
  artistId,
}) => {
  const titleLink = `/artist/${artistId}`;

  return (
    <ImageWithTitles
      titleLink={titleLink}
      title={title}
      image={image}
      round
      isArtist
    />
  );
};

export default ArtistContainer;
