import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation (
    $email: String!
    $password: String!
    $isArtist: Boolean!
    $artistDisplayName: String
    $firstName: String!
    $country: String
    $lastName: String!
  ) {
    token: createUser(
      email: $email
      password: $password
      isArtist: $isArtist
      country: $country
      artistDisplayName: $artistDisplayName
      firstName: $firstName
      lastName: $lastName
    ) {
      status
      message
    }
  }
`;

// mutation {
//   createUser(email:"testartist@yopmail.com",password:"Test@123456", isArtist: true,  artistDisplayName : "artistrock",firstName: "Tester", lastName:"Artist" ){
//     status
//     message
//   }
// }

export type CREATE_USERResonse = {
  token: string;
};

export interface CREATE_USERVariables {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  password: string;
  isArtist: boolean;
  artistDisplayName?: string;
}

export default CREATE_USER;
