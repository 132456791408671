import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, FormEvent, useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

import headphonesicon from "picture/headphones.png";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/react-hooks";
import ALL_COUNTRIES from "core/graphql/new/mutations/getAllCountries";

const useStyles = makeStyles((theme) =>
  createStyles({
    headphonesIcon: {
      backgroundImage: `url(${headphonesicon})`,
      width: "100px",
      height: "125px",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      backgroundRepeat: "no-repeat",
      opacity: "0.75",
      marginBottom: "30px",
    },
    body: {
      background:
        "linear-gradient(167.96deg, #10354D 22.92%, #02172A 48.44%, #202256 89.58%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      minHeight: "100vh",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "70px",
    },
    underline: {
      "&&&:before": {
        width: "0%",
      },
      "&&:after": {
        width: "100%",
      },
    },
    textfield: {
      width: "290px",
      height: "40px",
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      marginTop: "30px",
      dislpay: "flex",
      textAlign: "center",
      border: "none",
      marginBottom: 4,
      color: "#fff",
      "&:focus": {
        outline: "none",
        border: `.5px solid ${theme.palette.secondary.main}`,
      },
    },
    nextbutton: {
      // marginTop: "50px",
      backgroundColor: "#FD4060",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
    backButton: {
      // marginTop: "50px",
      backgroundColor: "rgba(93, 196, 178, 0.75)",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
    terms: {
      color: "#3D7670",
      fontWeight: "bolder",
      fontSize: "13px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    termsContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface RegistrationPageTwo {
  handleBack: any;
  handleSubmitPassword: any;
  handleSubmitEmail: any;
  choice:string,
  handleSignUp: any;
  isArtist?: boolean;
  handleSubmitArtistName?: any;
  handleSubmitFirstName: any;
  handleSubmitLastName: any;
  errorMessage?: string;
  loading?: boolean;
  setErrorMessage: any;
  setLoading: any;
  setSubmit: any;
  setAllCountriesByQuery: any;
  allContriesByQuery: any;
  setAlphaCode: any;
  alphaCode: any;
}

const RegistrationPageTwo: FC<RegistrationPageTwo> = ({
  handleBack,
  handleSubmitEmail,
  handleSubmitPassword,
  handleSignUp,
  isArtist = false,
  handleSubmitArtistName,
  handleSubmitFirstName,
  choice,
  handleSubmitLastName,
  errorMessage,
  loading = false,
  setErrorMessage,
  setLoading,
  setSubmit,
  setAllCountriesByQuery,
  setAlphaCode,
  allContriesByQuery,
  alphaCode,
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [confirmpasswordValid, setConfirmPasswordValid] = useState("");
  const [artistDisplayName, setArtistDisplayName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [validated, setValidated] = useState(false);
  const [lastname, setLastName] = useState("");
  const [agree, setAgree] = useState(false);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const { data: allCoutriesList, loading: countriesLoading } =
    useQuery(ALL_COUNTRIES);
  useEffect(() => {
    setAllCountriesByQuery(allCoutriesList?.getAllCountries);
  }, [countriesLoading]);

  const handleArtistDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setArtistDisplayName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    // eslint-disable-next-line
    const mailformat =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value.match(mailformat)) {
      setEmailValid("true");
      return;
    }
    setEmailValid("false");
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length >= 7) {
      setPasswordValid("true");
      return;
    }
    setPasswordValid("false");
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (e.target.value === password) {
      setConfirmPasswordValid("true");
      return;
    }
    setConfirmPasswordValid("false");
  };

  const handleSubmit = async (e: FormEvent) => {
    try {
      e.preventDefault();

      if (isArtist && artistDisplayName === "") {
        setErrorMessage(
          "You must supply an artistDisplayName if you are an artist."
        );
        return;
      }
      if (firstname === "") {
        setErrorMessage(
          "User validation failed: first name: path `first name` is required."
        );
        return;
      }
      if (lastname === "") {
        setErrorMessage(
          "User validation failed: last name: path `last name` is required."
        );
        return;
      }
      if (alphaCode === "") {
        setErrorMessage(
          "User validations failed: Country is required."
        );
        return;
      }
      if (password === "") {
        setErrorMessage(
          "User validation failed: password: path `password` is required."
        );
        return;
      }
      if (confirmpassword === "") {
        setErrorMessage(
          "User validation failed: confirm password: path `confirm password` is required."
        );
        return;
      }
      setErrorMessage("");
      handleSubmitPassword(password);
      handleSubmitArtistName?.(artistDisplayName);
      handleSubmitEmail(email);
      handleSubmitFirstName(firstname);
      handleSubmitLastName(lastname);
      await handleSignUp();
    } catch (err) {
    } finally {
      setLoading(false);
      setSubmit(false);
    }
  };

  if (errorMessage) {
    console.log("from page", errorMessage);
  }

  const handleCheck = () => {
    setAgree(!agree);
  };

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.headphonesIcon}></Box>
          <Typography variant="h5">User Information</Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "290px",
            }}
          >
            {isArtist && (
              <input
                value={artistDisplayName}
                autoComplete="none"
                onChange={handleArtistDisplayNameChange}
                placeholder="Your Artist/Group Name."
                type="text"
                className={classes.textfield}
              />
            )}

            <input
              value={firstname}
              autoComplete="first-name"
              onChange={handleFirstNameChange}
              placeholder="First Name"
              type="text"
              className={classes.textfield}
            />

            <input
              value={lastname}
              autoComplete="last-name"
              onChange={handleLastNameChange}
              placeholder="Last Name"
              type="text"
              className={classes.textfield}
            />

            {isArtist && (
              <div>
                <Autocomplete
                  id="country-select-demo"
                  options={allContriesByQuery}
                  className={classes.textfield}
                  placeholder="Select Country"
                  autoHighlight
                  disableClearable
                  getOptionLabel={(option: any) => {
                    setAlphaCode(option?.alpha2Code);
                    return option?.name;
                  }}
                  renderOption={(props: any, option: any) => {
                    return (
                      <>
                        <Box component="li">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              // margin: 0,
                              padding: 0,
                              // marginRight:'10px',
                              gap: "5px",
                            }}
                            className="d-flex m-0 p-0 align-items-center"
                          >
                            <p style={{ padding: 0, margin: 0 }}>
                              {props?.name}
                            </p>
                          </div>
                        </Box>
                      </>
                    );
                  }}
                  renderInput={(params: any) => {
                    return (
                      <TextField
                      // sx={{marginRight:'10px'}}
                        {...params}
                        placeholder={
                          countriesLoading
                            ? "Loading Countries..."
                            : "Choose country"
                        }
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    );
                  }}
                />
                {!alphaCode && validated ? (
                  <FormHelperText style={{ color: "red", fontSize: "10px" }}>
                    Please select a country
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </div>
            )}

            <input
              value={email}
              autoComplete="email"
              onChange={handleEmailChange}
              placeholder="Email Address"
              type="email"
              className={classes.textfield}
            />

            {emailValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {emailValid === "false" && (
              <Typography color="error" variant="body2">
                Email is Invalid
              </Typography>
            )}

            <input
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
              className={classes.textfield}
            />

            {passwordValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {passwordValid === "false" && (
              <Typography color="error" variant="body2">
                Needs to be at least 7 characters long
              </Typography>
            )}

            <input
              autoComplete="new-password"
              value={confirmpassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirm Password"
              type="password"
              className={classes.textfield}
            />

            {confirmpasswordValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {confirmpasswordValid === "false" && (
              <Typography color="error" variant="body2">
                Passwords Don't Match
              </Typography>
            )}

            {errorMessage && (
              <Typography
                color="error"
                style={{ textAlign: "center", marginTop: 20 }}
                variant="body2"
              >
                {errorMessage}
              </Typography>
            )}
            <Box mt={4} className={classes.termsContainer}>
              <Checkbox onChange={handleCheck} />
              <Typography>
                By clicking here, I state that I have read and understood the
                <span
                  className={classes.terms}
                  onClick={() => {
                    window.location.href =
                      "https://starfarm.com/terms&conditions";
                  }}
                >
                  {" "}
                  Terms and condition{" "}
                </span>
                and
                <span
                  className={classes.terms}
                  onClick={() => {
                    window.location.href = "https://starfarm.com/privacyPolicy";
                  }}
                >
                  {" "}
                  Privacy Policy{" "}
                </span>
              </Typography>
            </Box>
            <Box mt={4}>
              {loading && !errorMessage && !validated ? (
                <CircularProgress color="primary" />
              ) : (
                <Button
                  type="submit"
                  onClick={()=>setValidated(true)}
                  disabled={agree ? false : true}
                  className={classes.nextbutton}
                >
                  <Typography>Sign Up</Typography>
                </Button>
              )}
            </Box>
          </form>
          <Button
            onClick={handleBack}
            style={{ marginTop: "30px" }}
            className={classes.backButton}
          >
            <Typography>Back</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RegistrationPageTwo;
