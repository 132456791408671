import { Typography, Box, createStyles, makeStyles } from "@material-ui/core";
import TimePastFromNow from "components/@ui/TimePastFromNow";
import React, { FC } from "react";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      donorItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginVertical: 20,
        alignItems: "center",
        width: "100%",
      },
      donorEmail: {
        fontWeight: "normal",
        maxWidth: "100%",
      },
      infoContainer: {
        maxWidth: "25%",
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "column",
      },
      tipAmount: {
        color: "#3D7670",
        textAlign: "right",
        maxWidth: "100%",
        marginTop: "20px",
      },
      donor: {
        maxWidth: "70%",
      },
    })
  );

interface ITipItem {
  tip: Tip;
  loading?: boolean;
}

const TipItem: FC<ITipItem> = ({ tip, loading = false }) => {
  const classes = useStyles()();

  return (
    <Box key={tip._id} className={classes.donorItem}>
      <Box className={classes.donor}>
        <Typography variant="h5" className={classes.donorEmail}>
          {tip.streamer?.userName || "Anonymous"}
        </Typography>
      </Box>

      <Box className={classes.infoContainer}>
        <Typography className={classes.tipAmount} variant="h4">
          {`${tip.amount} Stars`}
        </Typography>

        <TimePastFromNow createdAt={tip.createdAt} />
      </Box>
    </Box>
  );
};

export default TipItem;
