import React, { FC } from "react";
import { useQuery } from "@apollo/react-hooks";
import GET_ARTIST_BY_ID, {
	GET_ARTIST_BY_IDResponse,
	GET_ARTIST_BY_IDVariables,
} from "core/graphql/new/queries/FindArtistById";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid/Grid";
import AlbumContainer from "containers/AlbumContainer";
import HorizontalList from "components/@ui/HorizontalList";
import Hidden from "@material-ui/core/Hidden/Hidden";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TipArtist from "pages/Desktop/components/TipArtistModal";
import ArtistTips from "containers/ArtistTips";
import { DateTime } from "luxon";
import SongList from "containers/SongList";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MoreMenuButton from "components/MoreMenuButton";
import { PlaylistType } from "models/Player";
import ShareButton from "components/Album/ShareButton";

interface IResponsiveDashboardProps
	extends RouteComponentProps<{ id: string }> { }

interface IStyleProps {
	bannerImage?: string;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
	featured: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		[theme.breakpoints.down("md")]: {
			paddingBottom: theme.spacing(6),
		},
	},

	block1: {
		flexDirection: "row",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column-reverse",
		},
	},
	backButton: {
		position: "absolute",
		top: "10px",
		left: "10px",
	},
	artistDescription: { fontSize: 16, color: "rgba(255,255,255, 0.5)" },
	descriptionTitle: { maxWidth: "700px" },
	heading: {
		position: "relative",
		background: (props) =>
			`linear-gradient(0deg, #02172a, #02172a33), url(${props.bannerImage}) no-repeat  center / cover`,
		width: "100%",
		height: "40vh",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		paddingBottom: "25px",
	},
	margining: {
		marginLeft: 10,
		marginTop: 10,
	},
}));

const ArtistResponsive: FC<IResponsiveDashboardProps> = ({ match }) => {
	const history = useHistory();
	const { data } = useQuery<
		GET_ARTIST_BY_IDResponse,
		GET_ARTIST_BY_IDVariables
	>(GET_ARTIST_BY_ID, { variables: { id: match.params.id } });

	const classes = useStyles({ bannerImage: data?.artist.bannerImage });

	const featuredAlbum = data?.artist.albums?.filter(
		(album) => album.featuredByArtist === true
	)[0];
	const handleBack = () => {
		history.goBack();
	};

	return (
		<>
			<Box mb={6} px={2} className={classes.heading}>
				<Hidden mdUp={true}>
					<Box className={classes.backButton}>
						<IconButton onClick={handleBack}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
				</Hidden>
				<Typography variant="h1" style={{ marginBottom: "16px" }}>{data?.artist.displayName}</Typography>

				<div style={{ display: "flex" }}>
					<TipArtist
						artistId={data?.artist._id}
						artistImage={data?.artist.image}
						artistName={data?.artist.displayName}
					/>

					<ShareButton
						image={data?.artist.image}
						url={data?.artist.shortLink}
					/>
				</div>
				<Box className={classes.margining}>
					<MoreMenuButton type="artist" id={data?.artist._id} />
				</Box>
			</Box>

			<Grid className={classes.block1} justify="space-between" container>
				<Grid item md={7} lg={7} sm="auto" xs="auto">
					<Box px={2}>
						<Typography variant="h4" gutterBottom>
							TOP SONGS
						</Typography>

						<SongList
							songs={data?.artist.songs
								?.filter((song) => song.disabled !== true)
								.sort((a, b) => b.plays - a.plays)
								.slice(0, 4)}
							playlistType={PlaylistType.ARTIST}
							playlistId={data?.artist._id}
						/>
					</Box>
				</Grid>

				{featuredAlbum && (
					<Grid item md={4} lg={4} xs={12} sm={12}>
						<Box className={classes.featured}>
							<Typography gutterBottom={true} variant="h4">
								FEATURED
							</Typography>

							<AlbumContainer
								title={featuredAlbum?.title}
								artistName={featuredAlbum?.primaryArtist?.displayName}
								isSingle={featuredAlbum?.isSingle}
								image={featuredAlbum?.imageUrl}
								albumId={featuredAlbum?._id}
								artistId={featuredAlbum?.primaryArtist?._id}
								tertiaryTitle={
									featuredAlbum.releaseDate
										? DateTime.fromISO(featuredAlbum.releaseDate).toFormat(
											"yyyy"
										)
										: "2021"
								}
							/>
						</Box>
					</Grid>
				)}
			</Grid>

			<Box pt={6} px={2}>
				<Typography variant="h4" gutterBottom={true}>
					ALBUMS
				</Typography>
			</Box>

			<HorizontalList id="artist-albums">
				{data?.artist.albums
					?.sort(
						(a, b) =>
							DateTime.fromISO(b.releaseDate!).toMillis() -
							DateTime.fromISO(a.releaseDate!).toMillis()
					)
					.filter((album) => album.disabled !== true)
					.map((album) => (
						<AlbumContainer
							albumId={album._id}
							artistId={album.primaryArtist?._id}
							key={album._id}
							image={album.imageUrl}
							title={album.title}
							artistName={album.primaryArtist?.displayName}
							isSingle={album.isSingle}
							tertiaryTitle={
								album.releaseDate
									? DateTime.fromISO(album.releaseDate).toFormat("yyyy")
									: "2021"
							}
						/>
					))}
			</HorizontalList>

			<Box pt={6} px={2}>
				<Typography variant="h4" gutterBottom={true}>
					ALL SONGS
				</Typography>

				<SongList
					songs={data?.artist.songs?.filter((song) => song.disabled !== true)}
					playlistType={PlaylistType.ARTIST}
					playlistId={data?.artist._id}
				/>
			</Box>

			<ArtistTips
				artistId={data?.artist._id}
				artistName={data?.artist.displayName}
				artistImage={data?.artist.image}
			/>

			{data?.artist.description && (
				<Box pt={12} px={2} className={classes.descriptionTitle}>
					<Typography variant="h4" gutterBottom={true}>
						ARTIST DESCRIPTION
					</Typography>

					<Typography
						variant="h5"
						style={{
							fontSize: 16,
							color: "rgba(255,255,255, 0.5)",
							whiteSpace: "pre-line",
						}}
					>
						{data?.artist.description}
					</Typography>
				</Box>
			)}

			<Box pb={20} />
		</>
	);
};

export default ArtistResponsive;
