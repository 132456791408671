import gql from "graphql-tag";

const GET_USER_TIPS = gql`
  query {
    transactions:tipsByCurrentUser{
        _id,
        streamer {
          _id,
          firstName,
          email,
        },
        recipient{
          _id,
          displayName
        }
        createdAt,
        amount
      }
  }
`;

export interface GET_USER_TIPSResponse {
    transactions: Transaction[];
}

export default GET_USER_TIPS;
