import gql from "graphql-tag";

const SEARCH_BY_TERM = gql`
	query ($term: String) {
		searchResponse: search(searchTerm: $term) {
			artists {
				displayName
				image
				_id
			}
			songs {
				name
				disabled
				image
				_id
				costPerPlay
				fileLink
				primaryArtist {
					displayName
					_id
				}
				album {
					title
					_id
				}
			}
			albums {
				title
				_id
				disabled
				primaryArtist {
					_id
					displayName
				}
				imageUrl
				releaseDate
			}
		}
	}
`;

export interface SEARCH_BY_TERMResponse {
	searchResponse: {
		albums: Array<NewAlbum>;
		songs: Array<NewSong>;
		artists: Array<NewArtist>;
	};
}

export interface SEARCH_BY_TERMVariables {
	term: string;
}

export default SEARCH_BY_TERM;
