import React, { useContext } from "react";
import { createStyles, makeStyles, Modal } from "@material-ui/core";
import ReportSongModal from "./ReportSongModal";
import ReportAlbumModal from "./ReportAlbumModal";
import ReportArtistModal from "./ReportArtistModal";
import { observer } from "mobx-react-lite";
import ReportFormContext from "stores/ReportFormStore";

const CombinedReportModal = observer(() => {
  const store = useContext(ReportFormContext);

  const styles = useStyles()();

  const handleClose = () => {
    store.closeModal();
  };

  return (
    <Modal
      open={store.open}
      onClose={handleClose}
      className={styles.modalContainer}
    >
      {store.type === "song" ? (
        <ReportSongModal handleClose={handleClose} />
      ) : store.type === "album" ? (
        <ReportAlbumModal handleClose={handleClose} />
      ) : store.type === "artist" ? (
        <ReportArtistModal handleClose={handleClose} />
      ) : (
        <></>
      )}
    </Modal>
  );
});

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      modalContainer: {
        width: 650,
        height: 475,
        borderRadius: 30,
        backgroundColor: "rgba(16, 53, 77)",
        margin: "auto",
      },
    })
  );

export default CombinedReportModal;
