import gql from "graphql-tag";

const FIND_PLAYLIST_BY_ID = gql`
  query findPlaylist($id: MongoID!) {
    playlist: findPlaylist(filter: { _id: $id }) {
      _id
      createdAt
      name
      image
      songs {
        costPerPlay
        image
        disabled
        _id
        name
        fileLink

        primaryArtist {
          displayName
          _id
          image
        }
        album {
          disabled
          title
          _id
        }
      }
      user {
        _id
        email
      }
    }
  }
`;

export interface FIND_PLAYLIST_BY_IDResponse {
  playlist: NewPlaylist;
}

export interface FIND_PLAYLIST_BY_IDVariables {
  id: string;
}

export default FIND_PLAYLIST_BY_ID;
