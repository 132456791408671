import Hidden from "@material-ui/core/Hidden/Hidden";

import LOGIN_USER, {
  LOGIN_USERResponse,
} from "core/graphql/new/mutations/Login";
import React, { useContext, useEffect, useState } from "react";
import LoginDesktop from "./LoginDesktop";
import LoginMobile from "./LoginMobile";
import TokenManager from "../../core/auth/TokenManager";
import { useHistory, useLocation } from "react-router-dom";
import useUnauthenticatedClient from "jobs/UnathenicatedClient";
import { observer } from "mobx-react-lite";
import getEnvironmentVariable from "../../core/utils/getEnvironmentVariable";
import { toJS } from "mobx";
import { fetchToken } from "../../fireBaseConfig";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

interface LoginParams {
  email: string;
  password: string;
}

const accountURL = getEnvironmentVariable("REACT_APP_BENE_URI");
const adminURL = getEnvironmentVariable("REACT_APP_ADMIN_URL");

const LoginResponsive = () => {
  const client = useUnauthenticatedClient();

  const redirectUrl = new URLSearchParams(window.location.search).get(
    "redirectUrl"
  );
  const manager = useContext(TokenManager);
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [failed, setFailed] = useState(false);

  const [token, setToken] = useState<string>();

  const [errorMessage, setErrorMessage] = useState("");

  const [isTokenFound, setTokenFound] = useState(false);
  const [tokenFcm, setTokenFcm] = useState<string | null>("");

  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await fetchToken(setTokenFound);
      return data;
    }
    setTokenFcm(localStorage.getItem("Fcm"));    
    tokenFunc();
  }, [setTokenFound]);

  const handleSubmit = async (email: string, password: string) => {
    if (email === "") {
      setErrorMessage("Please enter email");
      return;
    } else if (password === "") {
      setErrorMessage("Please enter password");
      return;
    }

    setLoading(true);
    // for (let i = 0; i < 2; i++) {
      console.log("localStorage",localStorage.getItem("Fcm"));
      
    try {
      // const { data } = await client.mutate<LOGIN_USERResponse>({
      const res = await client.mutate<LOGIN_USERResponse>({
        mutation: LOGIN_USER,
        variables: {
          email: email,
          password: password,
          webPushToken: localStorage.getItem("Fcm"),
        },
      });
      const data = res.data;
      if (!data) return; //undefined,null,0,'',NAN,false
      if (data && Array.isArray(data)) return;
      if (data && !Object.keys(data).length) return;
      if (data.login && !Object.keys(data.login).length) return;
      setToken(data.login.token);
      manager.storeToken(data.login.token);
      manager.setToken(data.login.token);
    } catch (err) {
      console.log(String(err).split(":"));
      const typedError: any = err as Error;
      setLoading(false);
      toast.error(String(err).split(":")[2])
      setFailed(true);
      setErrorMessage(typedError?.graphQLErrors?.[0]?.message);
    }
    // }
  };

  const tokens: any = manager.getToken();

  const handleLogin = async (token?: string) => {
    if (token) {
      setLoading(false);
      if (redirectUrl) {
        history.push(redirectUrl);
        return;
      }

      if (
        manager.decodedToken?.isArtist ||
        manager.decodedToken?.isBeneficiary
      ) {
        window.location.href = `${accountURL}?token=${token}`;
        localStorage.setItem("artist", `${manager.decodedToken?.isArtist}`);
        return;
      }

      if (manager.decodedToken?.isAdmin) {
        window.location.href = adminURL;
        localStorage.setItem("admin", `${manager.decodedToken?.isAdmin}`);
        return;
      }

      history.push("/");
    }

    setLoading(false);

    return;
  };

  useEffect(() => {
    handleLogin(token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>
      <Hidden mdUp={true}>
        <LoginMobile handleSubmit={handleSubmit} />
      </Hidden>

      <Hidden smDown={true}>
        <LoginDesktop
          handleSubmit={handleSubmit}
          errormessage={errorMessage}
          loading={loading}
          failed={failed}
        />
      </Hidden>
    </>
  );
};

export default observer(LoginResponsive);
