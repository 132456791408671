import React, { FC, FormEvent, useState, ChangeEvent, useEffect } from "react";

import Hidden from "@material-ui/core/Hidden";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import ImageWithTitles from "components/@ui/ImageWithTitles";
import { useLazyQuery } from "@apollo/react-hooks/lib/useLazyQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Grid from "@material-ui/core/Grid/Grid";
import NewPlaylistDialog from "pages/Desktop/components/NewPlaylistDialog";
import { useHistory, useLocation } from "react-router-dom";
import SEARCH_BY_TERM_LIBRARY, {
  SEARCH_BY_TERM_LIBRARYResponse,
  SEARCH_BY_TERM_LIBRARYVariables,
} from "core/graphql/new/queries/searchByTermLibrary";

interface ILibraryProps {}

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      activeSearchBar: {
        height: "30px",
        width: "60%",
        margin: "20px 5px 15px 0px",
        borderRadius: "25px",
        background: "rgb(255 255 255 / 0.24)",
        border: "none",
        paddingLeft: "20px",
        color: "#c1c1c1",
        "&:focus": {
          outline: "none",
          background: "#ffffff40",
        },
        "&::-webkit-input-placeholder": {
          color: "rgb(255 255 255 / 0.3)",
        },
      },
      block1: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: `5vh ${theme.spacing(8)}px 0px`,
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
        },
      },
      block2: {
        padding: `${theme.spacing(8)}px`,
        [theme.breakpoints.down("sm")]: {
          padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
      },
      box: {
        paddingBottom: "75px",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "15px",
        },
      },
    })
  );

const LibraryResponsive: FC<ILibraryProps> = (props) => {
  const history = useHistory();
  const classes = useStyles()();
  const location = useLocation();
  const queryString = location.search;
  const searchTerm = queryString.slice(2);
  const [inputvalue, setInputValue] = useState<any>("");
  const [search, { data }] = useLazyQuery<
    SEARCH_BY_TERM_LIBRARYResponse,
    SEARCH_BY_TERM_LIBRARYVariables
  >(SEARCH_BY_TERM_LIBRARY);

  console.log('data',data);
  

  const handleSearch = async () => {
    try {
      await search({
        variables: {
          term: searchTerm,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setInputValue(searchTerm);

    handleSearch();

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  const handleChange = (e: ChangeEvent<any>) => {
    setInputValue(e.target.value);
  };
  const handleSubmitSearch = async (e: FormEvent<any>) => {
    e.preventDefault();
    history.push(`/library?=${inputvalue}`);
  };

  return (
    <>
      <PageWithBackgroundImage>
        <div>
          <form
            onSubmit={handleSubmitSearch}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              style={{ marginLeft: "20px", marginRight: "20px" }}
              variant="h3"
            >
              Search
            </Typography>

            <input
              type="text"
              value={inputvalue}
              onChange={handleChange}
              placeholder="Search playlist"
              className={classes.activeSearchBar}
              autoFocus
            ></input>
          </form>
        </div>
        <Box className={classes.block1}>
          <Box>
            <Typography gutterBottom={true} variant="h1" color="primary">
              Library
            </Typography>
          </Box>
        </Box>

        <Box className={classes.block2}>
          <Grid container>
            <Hidden mdUp={true}>
              <Grid item sm={12} xs={12}>
                <Box className={classes.box}>
                  <NewPlaylistDialog />
                </Box>
              </Grid>
            </Hidden>

            <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
              <Box className={classes.box}>
                <ImageWithTitles
                  horizontal
                  title="Liked Songs"
                  titleLink={`/likedsongs`}
                  image={`https://i.pinimg.com/originals/73/15/da/7315da676f55e4707ea1766bdbd4e110.png`}
                />
              </Box>
            </Grid>

            {data?.search?.playlist?.map((playlist: any) => {
              console.log("playlist",playlist);
              return (
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                  <Box className={classes.box}>
                    <ImageWithTitles
                      image={
                        playlist.image ||
                        "https://image.shutterstock.com/image-illustration/vintage-vinyl-records-out-box-600w-1711637752.jpg"
                      }
                      title={playlist.name}
                      titleLink={`/playlist/${playlist._id}`}
                      horizontal
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>

        <Box height={200} pb={10} />
      </PageWithBackgroundImage>
    </>
  );
};

export default LibraryResponsive;
