import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import {
  Box,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TipItem from "components/Artist/tipItem";
import GET_ARTIST_TIPS, {
  GET_ARTIST_TIPSResponse,
  GET_ARTIST_TIPSVariables,
} from "core/graphql/new/queries/getArtistTips";
import React, { FC } from "react";
import TipArtist from "../pages/Desktop/components/TipArtistModal";
import TipArtistActions from "./TipArtistActions";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        paddingLeft: 16,
        paddingRight: 16,
        marginTop: 100,
      },
      donorContainer: {
        minHeight: 300,
        width: "100%",
        marginTop: 25,
        borderRadius: 15,
        paddingBottom: 20,
        paddingTop: 10,
      },
      viewReceipt: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        paddingVertical: 10,
      },
      buttonContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      recentTipsContainer: {
        paddingLeft: "10px",
        paddingRight: "10px",
        marginBottom: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "300px",
        width: "100%",
      },
    })
  );

interface IArtistTipsProps {
  artistId?: string;
  artistName?: string;
  artistImage?: string;
}

const ArtistTips: FC<IArtistTipsProps> = ({
  artistId = "",
  artistName,
  artistImage,
}) => {
  const classes = useStyles()();

  const { data, loading } = useQuery<
    GET_ARTIST_TIPSResponse,
    GET_ARTIST_TIPSVariables
  >(GET_ARTIST_TIPS, { variables: { artistId, limit: 5 } });

  return (
    <Box className={classes.container}>
      <Typography variant="h4">RECENT TIPS</Typography>

      <Box className={classes.donorContainer}>
        <>
          <Grid container>
            <Grid
              item
              className={classes.recentTipsContainer}
              lg={6}
              md={6}
              xl={6}
              sm={12}
              xs={12}
            >
              {data?.tips && data?.tips.length >= 1 && (
                <>
                  {data?.tips.map((tip) => (
                    <TipItem loading={loading} key={tip._id} tip={tip} />
                  ))}
                </>
              )}
              {data?.tips && data?.tips.length < 1 && (
                <Box>
                  <Typography variant="subtitle1" align="center">
                    Be the first to show your support for{" "}
                    {artistName || "this artist"}!
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid
              className={classes.buttonContainer}
              item
              lg={6}
              md={6}
              xl={6}
              sm={12}
              xs={12}
            >
              <Hidden smDown={true}>
                <TipArtistActions artistId={artistId} artistName={artistName} />
              </Hidden>
              <Hidden mdUp={true}>
                <TipArtist
                  artistId={artistId}
                  artistImage={artistImage}
                  artistName={artistName}
                />
              </Hidden>
            </Grid>
          </Grid>
        </>
      </Box>
    </Box>
  );
};

export default ArtistTips;
