import dotenv from 'dotenv';

dotenv.config();

const getEnvironmentVariable = (name: string) => {
  const environmentVariable = process.env[name];

  if (!environmentVariable) {
    throw new Error(`Environment variable "${name}" not found`);
  }

  return environmentVariable;
};

export default getEnvironmentVariable;
