import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import useUnauthenticatedClient from "jobs/UnathenicatedClient";
import { useLocation, useHistory } from "react-router-dom";
import RESET_PASSWORD, {
  RESET_PASSWORDResponse,
  RESET_PASSWORDVariables,
} from "../core/graphql/new/mutations/resetPassword";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface IResetPasswordProps {}

const useStyles = makeStyles({
  textfield: {
    width: "100%",
    maxWidth: "500px",
    height: "40px",
    background: "#dbdbdb26",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    "& .MuiInput-root": {
      width: "100%",
      height: "100%",
      borderRadius: "100px",
    },
    "& .MuiInput-underline": {
      "&:before": {
        width: "0%",
      },
      "&:after": {
        width: "90%",
        left: "5%",
      },
    },
  },
  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    backgroundColor: "rgb(255 255 255 / 21%)",
    padding: "50px",
    borderRadius: "10px",
    width: "700px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "#02172a",
  },
  title: {
    color: "white",
    fontFamily: "simplifica",
    fontSize: "64px",
    textAlign: "center",
  },
  subtitle: {
    color: "rgba(84, 186, 180, 0.5)",
    marginBottom: "40px",
    textAlign: "center",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: "35px",
    width: "205px",
    borderRadius: "25px",
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#fff",
    marginTop: "20px",
    fontSize: "14px",
    "&:hover": {
      color: "rgba(84, 186, 180, 0.5)",
    },
  },
  fullWidth: {
    width: "100%",
  },
  "@media (max-width: 420px)": {
    contentContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
});

const inputProps = {
  required: true,
  min: 0,
  style: {
    textAlign: "center",
    padding: "0px",
    height: "100%",
    borderRadius: "100px",
  },
};

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: FC<IResetPasswordProps> = (props) => {
  const location = useLocation();
  const mappedUrl = location.pathname.split("/");
  const userId = mappedUrl[2];

  const classes = useStyles();

  const history = useHistory();

  const [error, setError] = useState("");

  const client = useUnauthenticatedClient();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState({ visible: false, data: "" });
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (String(otp).length < 6) {
      setError("Enter full Otp");
      return;
    }

    if (!password || !confirm) {
      setError("You must supply both fields");
      return;
    }

    if (password !== confirm) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 7) {
      setError("Password must be atleast 7 characters long");
      return;
    }
    setLoading(true);
    setErrorMessage("");
    try {
      const { data } = await client.mutate<
        RESET_PASSWORDResponse,
        RESET_PASSWORDVariables
      >({
        mutation: RESET_PASSWORD,
        variables: {
          password,
          otp,
          userId: userId,
        },
      });

      if (data?.message) {
        setOpen({ visible: true, data: data?.message });
        history.push("/login");
      }
    } catch (err) {
      const typedError: any = err as Error;
      setErrorMessage(typedError?.graphQLErrors?.[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen({ visible: false, data: "" });
    setTimeout(() => {
      history.push("/login");
    }, 100);
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.contentContainer}>
          <Box display="flex" justifyContent="space-between">
            <Box className={classes.fullWidth}>
              <Typography variant="h2" className={classes.title}>
                Reset Password
              </Typography>

              <Typography variant="h4" className={classes.subtitle}>
                Please enter your otp and password.
              </Typography>
            </Box>

            {loading === true && <CircularProgress />}
          </Box>
          <form onSubmit={handleSubmit}>
            <Box className={classes.formContainer}>
              <TextField
                value={otp}
                onChange={(e) => {
                  setError("");
                  if (e.target.value.length > 6) {
                    return;
                  } else {
                    setOtp(e.target.value);
                  }
                }}
                placeholder="Otp"
                type="number"
                inputProps={{
                  required: true,
                  min: 0,
                  style: {
                    textAlign: "center",
                    padding: "0px",
                    height: "100%",
                    borderRadius: "100px",
                  },
                }}
                className={classes.textfield}
              />

              <TextField
                value={password}
                onChange={(e) => {
                  setError("");
                  return setPassword(e.target.value);
                }}
                placeholder="Password"
                type="password"
                inputProps={{
                  required: true,
                  min: 0,
                  style: {
                    textAlign: "center",
                    padding: "0px",
                    height: "100%",
                    borderRadius: "100px",
                  },
                }}
                className={classes.textfield}
              />

              <TextField
                value={confirm}
                onChange={(e) => {
                  setError("");
                  return setConfirm(e.target.value);
                }}
                placeholder="confirm password"
                type="password"
                inputProps={{
                  required: true,
                  min: 0,
                  style: {
                    textAlign: "center",
                    // backgroundColor: '#e8f0fe',
                    padding: "0px",
                    height: "100%",
                    borderRadius: "100px",
                  },
                }}
                className={classes.textfield}
              />

              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}

              <Box width="100%" display="flex" justifyContent="center">
                <Button
                  type="submit"
                  disabled={loading}
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>

          {/* <form onSubmit={handleSubmitForm}>
            <Box className={classes.formContainer}>
            <TextField
            value={email}
            onChange={handleEmailChange}
            placeholder="email@email.com"
            type="email"
                inputProps={inputProps as any}
                className={classes.textfield}
              />

              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              <Button
                type="submit"
                disabled={loading}
                color="primary"
                variant="contained"
                className={classes.button}
              >
                Submit
              </Button>

              <Link to="/login" className={classes.forgotPasswordLink}>
                Back to Login
              </Link>
            </Box>
          </form> */}
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
