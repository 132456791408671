import gql from "graphql-tag";

const RESET_PASSWORD = gql`
mutation($otp: String!, $userId: String!, $password: String!) {
  message: setPassword(userId:$userId, otp:$otp ,password: $password)
}
`;

export interface RESET_PASSWORDResponse {
  message: string;
}

export interface RESET_PASSWORDVariables {
  otp: string;
  password: string;
  userId: string;
}

export default RESET_PASSWORD;
