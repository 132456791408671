import React, { FC, useState } from "react";
import "./app.css"
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { fetchToken, onMessageListener } from "./fireBaseConfig";
import Notifications from "./components/Notifications/Notification";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";


import Root from "pages/Root";

import theme from "theme";

const App: FC = () => {


  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log(show, notification);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

    localStorage.clear();


  return (
    <>
      <div className="App">
        {show ? (
          <ReactNotificationComponent
            title={notification.title}
            body={notification.body}
          />
        ) : (
          <></>
        )}
      </div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Root />
      </ThemeProvider>

    </>
  )
}
export default App;
