import { FlagReasons } from "FlagReasons";
import gql from "graphql-tag";

const FLAG_ALBUM = gql`
  mutation($albumId: String, $reason: String, $additionalComment: String) {
    flagAlbum(albumId: $albumId, reason: $reason, additionalComment: $additionalComment){
        _id
    }
  }
`;

export interface FLAG_ALBUMVariables {
 albumId: string;
 reason: FlagReasons;
 additonalComment?: string;
}

export interface FLAG_ALBUMResponse {
    album: Album
}


export default FLAG_ALBUM;