import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "40%",
  },
  input: {
    background: "#dbdbdb26",
    borderRadius: "100px",
    padding: "10px 20px",
  },
}));

interface IPaymentInformationProps {
  errors?: string;
}

const PaymentInformation: FC<IPaymentInformationProps> = ({ errors }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CardNumberElement
        onChange={(e) => {
          console.log(e.error);
        }}
        options={{
          classes: {
            base: classes.input,
          },
          style: {
            base: {
              color: "white",
            },
          },
        }}
      />

      <CardExpiryElement
        options={{
          classes: {
            base: classes.input,
          },
          style: {
            base: {
              color: "white",
            },
          },
        }}
      />
      <CardCvcElement
        options={{
          classes: {
            base: classes.input,
          },
          style: {
            base: {
              color: "white",
            },
          },
        }}
      />

      {errors && <Typography color="error">{errors}</Typography>}
    </Box>
  );
};

export default PaymentInformation;
