import React, { FC } from "react";

import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles, createStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import defaultArtistImage from "../../picture/defaultArtistPicture.jpg";

interface PlayListListItemProps {
  id?: string;
  name?: string;
  image?: string;
  isSingle?: boolean;
  artist?: NewArtist;
  horizontal?: boolean;
  title?: string;
  subTitle?: string;
  round?: boolean;
  titleLink?: string;
  subTitleLink?: string;
  imageLink?: string;
  isArtist?: boolean;
  extraPadding?: boolean;
  tertiaryTitle?: string;
}

interface UseStlyesParams {
  image: string;
  round: boolean;
  horizontal: boolean;
  isArtist: boolean;
  extraPadding: boolean;
}

const useStyles = ({
  image,
  round,
  horizontal,
  isArtist,
  extraPadding,
}: UseStlyesParams) =>
  makeStyles((theme) => {
    let backgroundImage = image;

    if (backgroundImage === null && isArtist) {
      backgroundImage = defaultArtistImage;
    }

    return createStyles({
      container: {
        display: "inline-block",
        width: "40vw",
        height: "40vw",
        maxHeight: 200,
        maxWidth: 200,
        marginRight: extraPadding ? "35px" : theme.spacing(2),
        borderRadius: 6,
        [theme.breakpoints.down("sm")]: {
          width: horizontal ? "100%" : "40vw",
          height: horizontal ? "fit-content" : "40vw",
          alignItems: horizontal ? "center" : "",
          display: horizontal ? "flex" : "inline-block",
          maxWidth: horizontal ? "fit-content" : 200,
          marginRight: horizontal ? "0px" : theme.spacing(2),
        },
      },
      image: {
        backgroundImage: `url(${backgroundImage})`,

        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%",
        marginBottom: theme.spacing(2),
        borderRadius: round ? "50%" : 6,
        [theme.breakpoints.down("sm")]: {
          height: horizontal ? "75px" : "100%",
          width: horizontal ? "75px" : "100%",
        },
      },
      text: {
        textOverflow: "ellipsis",
        maxWidth: "100%",
        overflow: "hidden",
        textAlign: round ? "center" : "left",
        [theme.breakpoints.down("sm")]: {
          marginLeft: horizontal ? "20px" : "",
        },
      },
      dateTime: {
        textOverflow: "ellipsis",
        maxWidth: "100%",
        overflow: "hidden",
        marginTop: "8px",
        textAlign: round ? "center" : "left",
        [theme.breakpoints.down("sm")]: {
          marginLeft: horizontal ? "20px" : "",
        },
      },
      links: {
        textOverflow: "ellipsis",
        maxWidth: "100%",
        overflow: "hidden",
        color: "inherit",
        textDecoration: "none",
        textTransform: "none",
      },
    });
  });

const ImageWithTitles: FC<PlayListListItemProps> = ({
  image = "",
  subTitle,
  title,
  round = false,
  titleLink = "#",
  subTitleLink = "#",
  imageLink = titleLink,
  horizontal = false,
  isArtist = false,
  extraPadding = false,
  tertiaryTitle,
}) => {
  const classes = useStyles({
    image,
    round,
    horizontal,
    isArtist,
    extraPadding,
  })();

  return (
    <Box className={classes.container}>
      <Link to={imageLink}>
        <Box className={classes.image} />
      </Link>

      <Typography className={classes.text} variant="body1">
        <Link className={classes.links} to={titleLink}>
          {title}
        </Link>
      </Typography>

      <Typography
        className={classes.text}
        variant="body2"
        color="textSecondary"
      >
        <Link className={classes.links} to={subTitleLink}>
          {subTitle}
        </Link>
      </Typography>
      {tertiaryTitle && (
        <Typography
          color="primary"
          className={classes.dateTime}
          variant="body2"
        >
          {tertiaryTitle}
        </Typography>
      )}
    </Box>
  );
};

export default ImageWithTitles;
