import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReactNotificationComponent = (props: any) => {

    const { title, body } = props

    let hideNotif = title === "";

    if (!hideNotif) {
        toast.dark(<Display />);
    }

    function Display() {
        return (
            <div style={{color:"#3D7670",fontSize:"14px"}}>
                <h4>{title}</h4>
                <p>{body}</p>
            </div>
        );
    }

    return (
        <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        // <ToastContainer
        //     autoClose={3000}
        //     hideProgressBar
        //     newestOnTop={false}
        //     closeOnClick
        //     rtl={false}
        //     pauseOnFocusLoss={false}
        //     draggable
        //     pauseOnHover={false}
        // />
    );
};



export default ReactNotificationComponent;