import { Howl } from "howler";
import { observable, action } from "mobx";

class PlayerClient {
  @observable
  public musicPlayer?: Howl;

  @observable
  public pause: () => void;

  @observable
  public play: () => void;

  @observable
  public load: (uri: string) => void;

  @observable
  public seek: (position: number) => void;

  @observable
  public unload: () => void;

  @observable
  public setVolume: (volume: number) => void;

  @observable
  public updateTimes?: (
    currentTime: number,
    timeRemaining: number,
    songDuration: number
  ) => void;

  @observable
  public currentTime?: number;

  @observable
  public songDuration?: number;

  @observable
  public timeRemaining?: number;

  @observable
  public completed?: boolean = false;

  @action
  public setUpdateTimes = (
    updateFunction:
      | ((
          currentTime: number,
          timeRemaining: number,
          songDuration: number
        ) => void)
      | undefined
  ) => {
    this.updateTimes = updateFunction;
  };

  @action
  public forceUpdateTimes = () => {
    if (this.musicPlayer && this.updateTimes) {
      const currentTime = this.musicPlayer.seek() as number;
      this.currentTime = this.musicPlayer.seek() as number;
      const duration = this.musicPlayer.duration();
      const timeRemaining = duration - currentTime;

      this.updateTimes(currentTime, timeRemaining, duration);
    }
  };

  @action
  public updateTimeLoop = () => {
    if (this.musicPlayer && this.updateTimes && this.musicPlayer.playing()) {
      const currentTime = this.musicPlayer.seek() as number;
      this.currentTime = this.musicPlayer.seek() as number;
      const duration = this.musicPlayer.duration();
      const timeRemaining = duration - currentTime;
      this.timeRemaining = timeRemaining;

      this.updateTimes(currentTime, timeRemaining, duration);
    }

    setTimeout(
      () => {
        this.updateTimeLoop();
      },
      this.timeRemaining! < 3 ? 100 : 500
    );
  };

  constructor() {
    this.setVolume = (volume: number) => {
      if (this.musicPlayer) {
        this.musicPlayer.volume(volume);
      }
    };

    this.pause = () => {
      if (this.musicPlayer) {
        this.musicPlayer.pause();
      }
    };

    this.play = () => {
      if (this.musicPlayer) {
        this.musicPlayer.play();
      }
    };

    this.load = (uri: string) => {
      if (this.musicPlayer) {
        this.unload();
      }

      this.musicPlayer = new Howl({
        src: uri,
        html5: true,
        autoplay: true,
        format: ["mp3"],
      });

      this.updateTimeLoop();
    };

    this.unload = () => {
      if (this.musicPlayer) {
        this.musicPlayer.unload();
      }
      return;
    };

    this.seek = (position: number) => {
      if (this.musicPlayer) {
        this.musicPlayer.seek(position);
      }
    };
  }
}

export default PlayerClient;
