import React, { FC, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import FIND_PLAYLIST_BY_ID, {
	FIND_PLAYLIST_BY_IDResponse,
	FIND_PLAYLIST_BY_IDVariables,
} from "core/graphql/new/queries/FindPlaylistById";
import { useHistory, useParams } from "react-router-dom";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import DeletePlaylistButton from "pages/Desktop/components/DeletePlaylistButton";
import Hidden from "@material-ui/core/Hidden/Hidden";
import { IconButton } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SongList from "containers/SongList";
import { DateTime } from "luxon";
import PlayButton from "components/Album/PlayButton";
import { PlaylistType } from "models/Player";

interface IPlaylistPageProps {}

interface UseStylesParams {
	image?: string;
}

const useStyles = ({ image = "" }: UseStylesParams) =>
	makeStyles((theme) =>
		createStyles({
			image: {
				backgroundImage: `url('${image}')`,
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				width: "100vw",
				height: "100vw",
				maxWidth: 300,
				maxHeight: 300,
				borderRadius: 6,
				marginRight: theme.spacing(2),
				[theme.breakpoints.down("md")]: {
					marginBottom: theme.spacing(2),
				},
			},
			block1: {
				display: "flex",
				alignItems: "center",
				flexWrap: "wrap",
				padding: `20vh ${theme.spacing(8)}px 0px`,
				[theme.breakpoints.down("md")]: {
					padding: theme.spacing(2),
				},
			},
			block2: {
				padding: `${theme.spacing(8)}px`,
				[theme.breakpoints.down("md")]: {
					padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
				},
			},
			managePlaylist: {
				display: "flex",
				alignItems: "center",
				marginBottom: "15px",
			},
			backButton: {
				width: "100%",
				display: "flex",
				paddingRight: "10px",
				paddingTop: "10px",
			},
		})
	);

const PlaylistPage: FC<IPlaylistPageProps> = () => {
	const params = useParams<{ playlistId: string }>();

	const { playlistId } = params;

	const history = useHistory();

	const { data } = useQuery<
		FIND_PLAYLIST_BY_IDResponse,
		FIND_PLAYLIST_BY_IDVariables
	>(FIND_PLAYLIST_BY_ID, { variables: { id: playlistId } });

	const playlistpicture = useMemo(() => {
		return (
			data?.playlist.image ||
			data?.playlist.songs?.[0]?.image ||
			`https://image.shutterstock.com/image-illustration/vintage-vinyl-records-out-box-600w-1711637752.jpg`
		);
	}, [data]);

	const classes = useStyles({ image: playlistpicture })();

	const handleBack = () => {
		history.goBack();
	};

	if (data && data.playlist.createdAt) {
		console.log(DateTime.fromISO(data?.playlist.createdAt));
	}

	return (
		<PageWithBackgroundImage>
			<Hidden mdUp={true}>
				<Box className={classes.backButton}>
					<IconButton onClick={handleBack}>
						<ChevronLeftIcon />
					</IconButton>
				</Box>
			</Hidden>
			<Box className={classes.block1}>
				<Box className={classes.image} />

				<Box>
					<Box className={classes.managePlaylist}>
						<Typography
							style={{ marginRight: "15px" }}
							variant="h1"
							color="primary"
						>
							{data?.playlist.name}
						</Typography>
						<DeletePlaylistButton playlistId={data?.playlist._id} />
					</Box>

					<Typography gutterBottom={true}>
						{data?.playlist.createdAt
							? DateTime.fromISO(data?.playlist.createdAt).toFormat("yyyy")
							: "2021"}{" "}
						|{" "}
						{data?.playlist.songs?.filter((song) => song.disabled !== true)
							.length || 0}{" "}
						Songs
					</Typography>

					<PlayButton
						songs={data?.playlist.songs?.filter(
							(song) => song.disabled !== true
						)}
						image={data?.playlist.image}
					/>
				</Box>
			</Box>

			<Box className={classes.block2}>
				<Typography gutterBottom={true} variant="h4">
					Playlist Tracks
				</Typography>

				<SongList
					songs={data?.playlist.songs?.filter((song) => song.disabled !== true)}
					playlistType={PlaylistType.PLAYLIST}
					playlistId={data?.playlist._id}
				/>
			</Box>

			<Box height={200} pb={10} />
		</PageWithBackgroundImage>
	);
};

export default PlaylistPage;
